export class Producto {
  constructor(
    public readonly Id: number,
    public readonly Codigo: string,
    public readonly Nombre: string,
    public readonly CodigoArancelario: string,
    public readonly Derechos: number,
    public readonly PorcentajeIva: number,
    public readonly TasaEstadistica: number,
    public readonly ImpuestoInterno: number,
    public readonly Activo: boolean
  ) {}
}

export class SearchProductoRequest {
  constructor(
    public readonly PageSize: number | null,
    public readonly PageNumber: number | null,
    public readonly OrderAscending: boolean | null,
    public readonly OrderFieldName: string,
    public readonly Codigo: string,
    public readonly Nombre: string,
    public readonly CodigoArancelario: string,
    public readonly VerSoloActivos: boolean | null
  ) {}
}
export class SaveProductoDto {
  constructor(
    public readonly Codigo: string,
    public readonly Nombre: string,
    public readonly CodigoArancelario: string,
    public readonly Derechos: number,
    public readonly PorcentajeIva: number,
    public readonly TasaEstadistica: number,
    public readonly ImpuestoInterno: number,
    public Activo: boolean
  ) {}
}

export class SearchProductoResponse {
  constructor(
    public readonly count: number,
    public readonly result: Producto[]
  ) {}
}
