import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CrudService } from '.';
import { GenericResponse, ParametroSistema, SearchParametroSistemaRequestDto, SearchParametroSistemaResponseDto } from '../model';
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})

export class ParametroSistemaService extends CrudService<ParametroSistema, number, ParametroSistema, SearchParametroSistemaRequestDto> { 
  constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
    super(_http,'parametroSistema', sharedFunctions);
  }

  getCode(codigo: string): Observable<GenericResponse<ParametroSistema>> {
    return this._http.get<GenericResponse<ParametroSistema>>(this._serviceUrl + '/code/' + codigo, this.httpOptions);
  }

  getAnonymousCode(codigo: string): Observable<GenericResponse<ParametroSistema>> {
    return this._http.get<GenericResponse<ParametroSistema>>(this._serviceUrl + '/anonymous-code/' + codigo, this.httpOptions);
  }
}