export class DestinoEnvio {
  constructor(
    public readonly Id: number,
    public readonly Codigo: string,
    public readonly Nombre: string,
    public readonly CostoEnvio: number,
    public readonly Activo: boolean
  ) {}
}

export class SearchDestinoEnvioRequest {
  constructor(
    public readonly PageSize: number | null,
    public readonly PageNumber: number | null,
    public readonly OrderAscending: boolean | null,
    public readonly OrderFieldName: string,
    public readonly Codigo: string,
    public readonly Nombre: string,
    public readonly VerSoloActivos: boolean | null
  ) {}
}
export class SaveDestinoEnvioDto {
  constructor(
    public readonly Codigo: string,
    public readonly Nombre: string,
    public readonly CostoEnvio: number,
    public Activo: boolean
  ) {}
}

export class SearchDestinoEnvioResponse {
  constructor(
    public readonly count: number,
    public readonly result: DestinoEnvio[]
  ) {}
}

export class DestinoList {
  constructor(
  public readonly Id: number,
  public readonly Nombre: string
  ) {}
}
