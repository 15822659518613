import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Location, DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { GridPageSettings, MenuFuncionCodes, TipoDocumento, SearchTipoDocumentoRequest, SavePersonaDto, Persona, SearchUsuariosItemDto, SearchUsuariosRequestDto } from 'src/app/shared/model';
import { PersonaService, UsuarioService, MenuFuncionService, TipoDocumentoService } from 'src/app/shared/services';

@Component({
  selector: 'app-personaEdit',
  templateUrl: './personaEdit.component.html',
  styleUrls: ['./personaEdit.component.scss']
})

export class PersonaEditComponent implements OnInit {
  personaFormGroup: FormGroup;
  personaId: number;

  public pageSettings: PageSettingsModel;
  public pageSizes: number[];

  isValidating: boolean = false;
  isSearching: boolean = false;
  isUsuariosAsociadosSearching: boolean = false;
  isPersonSaving: boolean = false;
  isTipoDocumentoSearching: boolean = false;
  isPersonaSearching: boolean = false;

  canEdit: boolean = false;
  canDelete: boolean = false;
  canAdd: boolean = false;
  canView: boolean = false;
  puedeVerUsuario: boolean = false;

  activo: boolean = true;
  msgError: string = "";
  usuariosAsociadosError: string = "";
  persona: Persona = new Persona(null, null, null, null, null, null, null, true, null, null, null);
  userImageExtension: string = "";
  tipoDocumentoList: TipoDocumento[];
  usuariosAsociadosList: any[];

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    public dialog: MatDialog,
    private sharedFunctions: SharedFunctions,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    public tipoDocumentoService: TipoDocumentoService,
    private personaService: PersonaService,
    private menuService: MenuFuncionService,
    public usuarioService: UsuarioService) {
  }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuService.checkMenuPadre(MenuFuncionCodes.PERSONA).subscribe(data => {
      if (data != null && data.Success && data?.Result) {
        this.canEdit = data.Result.some(x => x == MenuFuncionCodes.PERSONA_EDIT);
        this.canAdd = data.Result.some(x => x == MenuFuncionCodes.PERSONA_ADD);
        this.canView = data.Result.some(x => x == MenuFuncionCodes.PERSONA_LIST);
        this.canDelete = data.Result.some(x => x == MenuFuncionCodes.PERSONA_DELETE);

        this.loadView();
      }
      else {
        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
        this.onCancel();
      }
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    this.pageSizes = GridPageSettings.PageSizeList;
    this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[0], pageSizes: this.pageSizes };

    this.buildPersonForm();
    this.getTiposDocumento();

    this.loadEntity();

    this.menuService.checkMenu(MenuFuncionCodes.USER_LIST).subscribe(data => {
      if (data != null && data.Success && data?.Result)
        this.puedeVerUsuario = true;
    },
    (err: HttpErrorResponse) => {
    });
  }

  buildPersonForm() {
    this.personaFormGroup = this.formBuilder.group({
      'Nombre': [null, [Validators.required, Validators.maxLength(150)]],
      'Apellido': [null, [Validators.required, Validators.maxLength(150)]],
      'TipoDocumentoId': [null],
      'NroDocumento': [null, Validators.maxLength(100)],
      'FechaNacimiento': [null],
      'MailContacto': [null, [Validators.maxLength(255), Validators.email]]
    });
  }

  getTiposDocumento() {
    var r = new SearchTipoDocumentoRequest(null, null, true, "Nombre", null, null, true);
    this.isTipoDocumentoSearching = true;

    this.tipoDocumentoService.search(r).subscribe(data => {
      this.isTipoDocumentoSearching = false;
      if (data != null) {
        this.tipoDocumentoList = data?.result;
      }
    },
    (err: HttpErrorResponse) => {
      this.isTipoDocumentoSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  isProcessing() {
    return (this.isSearching || this.isPersonSaving);
  }

  loadEntity() {
    this.route.params.subscribe(params => {
      if (params['id'] != null && params['id'] != '0') {
        this.personaId = Number(params['id']);

        if (this.personaId > 0) {
          if(this.canView || this.canEdit) {
            this.isSearching = true;
            this.personaService.get(this.personaId).subscribe(data => {
              this.isSearching = false;
              if (data != null && data.Success) {
                this.persona = data.Result;

                if (this.persona != null && this.persona.Id > 0) {
                  if (this.canEdit) {
                    this.personaFormGroup.patchValue(this.persona);
                    this.personaFormGroup.get("FechaNacimiento").setValue(this.datePipe.transform(this.persona.FechaNacimiento, "yyyy-MM-dd"));

                    this.getUsuariosAsociados();
                  }
                }
                else
                  this.msgError = "No se encontró la persona seleccionada.";
              }
              else
                this.msgError = "Ocurrió un error al intentar obtener los datos de la persona.";
            },
            (err: HttpErrorResponse) => {
              this.isSearching = false;
              this.sharedFunctions.handleError(err, true);
            })
          } 
          else {
            this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
            this.onCancel();    
          }
        }
        else
          this.msgError = "Item no válido.";
      }
      else {
        if (this.canAdd) {
          this.persona = new Persona(null, null, null, null, null, null, null, true, null, null, null);
          this.personaFormGroup.patchValue(this.persona);
        }
        else {
          this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
          this.onCancel(); 
        }
      }
    });
  }

  getUsuariosAsociados() {
    if (this.personaId > 0) {
      var r = new SearchUsuariosRequestDto(null, null, true, "Login", null, null, null, null, null, null, this.personaId, null, null, true, null);
      this.isUsuariosAsociadosSearching = true;

      this.usuarioService.search(r).subscribe(data => {
        this.isUsuariosAsociadosSearching = false;
          if (data != null) {
            this.usuariosAsociadosList = data.result;
          }
          else
            this.usuariosAsociadosError = "Ocurrió un error al intentar obtener los datos de los usuarios.";
      },
      (err: HttpErrorResponse) => {
        this.isUsuariosAsociadosSearching = false;
        this.sharedFunctions.handleError(err, true);
      })
    }
  }

  clearDate(formFieldName: string) {
    if (formFieldName != null && formFieldName.length > 0) {
      if (this.personaFormGroup.get(formFieldName) != null && this.personaFormGroup.get(formFieldName)?.value)
        this.personaFormGroup.get(formFieldName).setValue(null);
    }
  }

  getErrorNombre() {
    var v = this.personaFormGroup.get('Nombre');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorApellido() {
    var v = this.personaFormGroup.get('Apellido');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorFechaNacimiento() {
    return this.personaFormGroup.get('FechaNacimiento').hasError('required') ? 'El campo es obligatorio' : '';
  }

  getErrorTipoDocumentoId() {
    return this.personaFormGroup.get('TipoDocumentoId').hasError('required') ? 'El campo es obligatorio' : '';
  }

  getErrorNroDocumento() {
    var v = this.personaFormGroup.get('NroDocumento');
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorMailContacto() {
    var v = this.personaFormGroup.get('MailContacto');
    if (v.hasError('maxlength'))
      return 'Límite de 255 caracteres alcanzado';
    if (v.hasError('email'))
      return 'Debe ingresar un email con formato válido';
    return '';
  }

  hasPersonaFotoDniFrente() {
    return (this.persona?.FotoDniFrenteUrl != null && this.persona?.FotoDniFrenteUrl.length > 0);
  }

  hasPersonaFotoDniDorso() {
    return (this.persona?.FotoDniDorsoUrl != null && this.persona?.FotoDniDorsoUrl.length > 0);
  }

  hasPersonaFotoSelfie() {
    return (this.persona?.FotoSelfieUrl != null && this.persona?.FotoSelfieUrl.length > 0);
  }

  onGuardarPersona() {

    if (!this.personaFormGroup.valid) {
      this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
      return false;
    }

    var r = new SavePersonaDto(null, null, null, null, null, null, true, null, null, null, null, null, null);

    r =
    {
      ...this.personaFormGroup.value,
      ...
      {
        Id: (this.persona?.Id ?? 0),
        Activo: this.persona.Activo
      }
    };

    this.isPersonSaving = true;
    this.personaService.save(r).subscribe(data => {
      this.isPersonSaving = false;
      if (data != null && data.Success && data.Result != null && data.Result.Id > 0) {
        this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
        this.onCancel();
      }
      else
        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
    },
      (err: HttpErrorResponse) => {
        this.isPersonSaving = false;
        this.sharedFunctions.handleError(err, true);
      })
  }

  onCancel() {
    this.location.back();
  }

  editUser(item: SearchUsuariosItemDto) {
    if (!item || item.Id == null || item.Id <= 0) {
      this.sharedFunctions.showMessage("Debe seleccionar un item", "Error");
      return false;
    }

    if (!this.puedeVerUsuario)
      return false;

    this.router.navigate(['usuario-edit', item.Id]);
  }
}
