export class Origen {
  constructor(
    public readonly Id: number,
    public readonly Codigo: string,
    public readonly Nombre: string,
    public readonly CostoPorKilo: number,
    public readonly CostoAlmacenaje: number,
    public readonly DiasAlmacenaje: number,
    public readonly Handling: number,
    public readonly Activo: boolean
  ) {}
}

export class SearchOrigenRequest {
  constructor(
    public readonly PageSize: number | null,
    public readonly PageNumber: number | null,
    public readonly OrderAscending: boolean | null,
    public readonly OrderFieldName: string,
    public readonly Codigo: string,
    public readonly Nombre: string,
    public readonly VerSoloActivos: boolean | null
  ) {}
}
export class SaveOrigenDto {
  constructor(
    public readonly Codigo: string,
    public readonly Nombre: string,
    public readonly CostoPorKilo: number,
    public readonly CostoAlmacenaje: number,
    public readonly DiasAlmacenaje: number,
    public readonly Handling: number,
    public Activo: boolean
  ) {}
}

export class SearchOrigenResponse {
  constructor(
    public readonly count: number,
    public readonly result: Origen[]
  ) {}
}

export enum OrigenEnum {
  Miami = 3,
  China = 4
}