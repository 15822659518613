<mat-card>
  <mat-card-header>
    <mat-card-title>Gestión de Cotización</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>

    <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
    <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
    <mat-label *ngIf="!isSearching && msgError.length > 0">{{msgError}}</mat-label>

    <div *ngIf="!isSearching && item != null">

      <ng-container *ngIf="canEdit">

        <form [formGroup]="formGroup">

          <div fxLayout="row stretch" fxLayout.xs="column">

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Fecha Cotización*</mat-label>
                <input matInput [matDatepicker]="fechaCotizacionPicker" formControlName="FechaCotizacion" readonly (click)="fechaCotizacionPicker.open()">
                <mat-datepicker-toggle style="display:inline-block;" matSuffix [for]="fechaCotizacionPicker">
                </mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix (click)="clearDate('FechaCotizacion')">
                  <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #fechaCotizacionPicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Valor Compra Oficial*</mat-label>
                <input matInput type="number" formControlName="ValorCompraOficial" (keyup.enter)="onGuardar()">
                <mat-error *ngIf="!formGroup.controls['ValorCompraOficial'].valid && formGroup.controls['ValorCompraOficial'].touched">
                  {{ getErrorValorCompraOficial() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Valor Venta Oficial*</mat-label>
                <input matInput type="number" formControlName="ValorVentaOficial" (keyup.enter)="onGuardar()">
                <mat-error *ngIf="!formGroup.controls['ValorVentaOficial'].valid && formGroup.controls['ValorVentaOficial'].touched">
                  {{ getErrorValorVentaOficial() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Valor Compra Tarjeta*</mat-label>
                <input matInput type="number" formControlName="ValorCompraTarjeta" (keyup.enter)="onGuardar()">
                <mat-error *ngIf="!formGroup.controls['ValorCompraTarjeta'].valid && formGroup.controls['ValorCompraTarjeta'].touched">
                  {{ getErrorValorCompraTarjeta() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Valor Venta Tarjeta*</mat-label>
                <input matInput type="number" formControlName="ValorVentaTarjeta" (keyup.enter)="onGuardar()">
                <mat-error *ngIf="!formGroup.controls['ValorVentaTarjeta'].valid && formGroup.controls['ValorVentaTarjeta'].touched">
                  {{ getErrorValorVentaTarjeta() }}
                </mat-error>
              </mat-form-field>
            </div>

          </div>

        </form>

        <div fxLayout="row stretch" fxLayout.xs="column">
          <div fxFlex=33.33 fxFlex.xs=100 class="flex-item">
            <mat-checkbox [(ngModel)]="item.Activo" (keyup.enter)="onGuardar()" color="primary">Activo</mat-checkbox> 
          </div>
        </div>

      </ng-container>

      <ng-container *ngIf="!canEdit">

        <div fxLayout="row stretch" class="zb-readonly">
    
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Fecha Cotización</b>: {{item.FechaCotizacion}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Activo</b>: {{item.Activo ? 'Sí' : 'No'}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Valor Compra Oficial</b>: {{item.ValorCompraOficial | number:'1.2-2'}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Valor Venta Oficial</b>: {{item.ValorVentaOficial | number:'1.2-2'}}%</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Valor Compra Tarjeta</b>: {{item.ValorCompraTarjeta | number:'1.2-2'}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Valor Venta Tarjeta</b>: {{item.ValorVentaTarjeta | number:'1.2-2'}}%</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

        </div>

      </ng-container>

    </div>

  </mat-card-content>

  <mat-card-actions style="margin-bottom: 20px;">
    <div fxLayout="row stretch" fxLayout.xs="column">
      <button mat-raised-button (click)="onGuardar()" *ngIf="canEdit" [disabled]="isProcessing()" color="primary" class="zb-button">{{isSaving ? 'Guardando...' : 'Guardar'}}</button>
      <button mat-raised-button (click)="onCancel()" color="warn" class="zb-button">{{canAdd || canEdit ? 'Cancelar' : 'Cerrar'}}</button>
      <button mat-raised-button (click)="onDelete()" *ngIf="canDelete && item != null && item.Id > 0" [disabled]="isSaving" color="warn" class="zb-button">
        {{ isDeleting ? 'Eliminando...' : 'Eliminar'  }}
      </button>
    </div>
  </mat-card-actions>

</mat-card>