import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { CrudService } from "./crud.service";
import { GenericResponse, MonedaCotizacion, SearchMonedaCotizacionRequest, SearchMonedaCotizacionResponse, SaveMonedaCotizacionDto } from "../model/index";
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})
export class MonedaCotizacionService extends CrudService<MonedaCotizacion, number, SaveMonedaCotizacionDto, SearchMonedaCotizacionRequest> {

  constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
    super(_http,'monedaCotizacion', sharedFunctions);

  }
}