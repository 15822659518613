import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { CrudService } from "./crud.service";
import { GenericResponse, CodigoPromocional, SearchCodigoPromocionalRequest, SearchCodigoPromocionalResponse, SaveCodigoPromocionalDto } from "../model/index";
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})
export class CodigoPromocionalService extends CrudService<CodigoPromocional, number, SaveCodigoPromocionalDto, SearchCodigoPromocionalRequest> {

  constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
    super(_http,'codigoPromocional', sharedFunctions);

  }
}