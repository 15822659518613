<mat-nav-list style="max-width: 250px;">

  <ng-container *ngFor="let item of menu">
    <ng-container *ngTemplateOutlet="Recursion; context: { item: item }"></ng-container>
  </ng-container>

</mat-nav-list>

<ng-template #Recursion let-item="item">
  <ng-container *ngIf="validateMenu(item)">
    <a mat-list-item *ngIf="!hasMenuChildren(item)" routerLink="{{item.Link}}" routerLinkActive="list-item-active">
      <i class="fa {{item.Icono}}" *ngIf="showMenuIcon(item)" aria-hidden="true"
        style="margin-right: 5px;"></i>{{item.Nombre}}
    </a>

    <mat-expansion-panel mat-list-item *ngIf="hasMenuChildren(item)" class="expansion">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i class="fa {{item.Icono}}" aria-hidden="true" *ngIf="showMenuIcon(item)"
            style="margin-right: 5px;"></i>{{item.Nombre}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let subitem of item.MenuHijos">
        <ng-container *ngIf="!searchTerm || matchesSearchTermChildren(subitem)">
          <ng-container *ngTemplateOutlet="Recursion; context: { item: subitem }"></ng-container>
        </ng-container>
      </ng-container>
    </mat-expansion-panel>
  </ng-container>
</ng-template>