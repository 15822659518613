import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { GridPageSettings, MenuFuncionCodes, SaveTenantDto, Tenant } from 'src/app/shared/model';

import { TenantService } from 'src/app/shared/services/tenant.service';
import { MenuFuncionService } from 'src/app/shared/services/menuFuncion.service';

@Component({
  selector: 'app-tenantEdit',
  templateUrl: './tenantEdit.component.html'
})

export class TenantEditComponent implements OnInit {
  formGroup: FormGroup;
  tenantId: number;

  public pageSettings: PageSettingsModel;
  public pageSizes: number[];

  isValidating: boolean = false;
  isSearching: boolean = false;
  isSaving: boolean = false;

  canView: boolean = false;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  canAdminEdit: boolean = false;

  msgError: string = "";
  item: Tenant;
  tenantImageBase64: string | ArrayBuffer = null;
  tenantImageExtension: string = "";

  constructor (
    private formBuilder: FormBuilder,
    private location: Location,
    public dialog: MatDialog,
    private sharedFunctions: SharedFunctions,
    private route: ActivatedRoute,
    private tenantService: TenantService,
    private menuService: MenuFuncionService
  ) {
  }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuService.checkMenuPadre(MenuFuncionCodes.TENANT).subscribe(data => {
      if (data != null && data.Success && data?.Result) {
        this.isValidating = false;
        this.canEdit = data.Result.some(x => x == MenuFuncionCodes.TENANT_EDIT);
        this.canAdd = data.Result.some(x => x == MenuFuncionCodes.TENANT_ADD);
        this.canView = data.Result.some(x => x == MenuFuncionCodes.TENANT_LIST);
        this.canDelete = data.Result.some(x => x == MenuFuncionCodes.TENANT_DELETE);
        this.canAdminEdit = data.Result.some(x => x == MenuFuncionCodes.TENANT_ADMIN_EDIT);

        this.loadView();
      }
      else {
        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
        this.onCancel();
      }
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    this.pageSizes = GridPageSettings.PageSizeList;
    this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[0], pageSizes: this.pageSizes };

    this.buildForm();
    this.loadEntity();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      'Nombre': [null, [Validators.required, Validators.maxLength(150)]],
      'NombreCorto': [null],
      'NroDocumento': [null],
      'Telefono': [null],
      'Direccion': [null],
      'Email': [null],
      'SitioWeb': [null]
    });
  }

  convertFileToBase64(event) {
    this.tenantImageBase64 = null;
    const file = event.target.files[0];

    if (file != null) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
      };
      reader.onloadend = () => {
        this.tenantImageBase64 = reader.result;
        this.tenantImageExtension = (file?.name?.split('.')?.pop() ?? "");
        // console.log(reader.result);
      };
    }
  }

  isProcessing() {
    return (this.isSearching || this.isSaving || this.isValidating);
  }

  loadEntity() {
    this.route.params.subscribe(params => {
      if (params['id'] != null && params['id'] != '0' && params['id'] != '00') {
        this.tenantId = Number(params['id']);

        if (this.tenantId > 0)
          this.loadData();
        else
          this.msgError = "Item no válido.";
      }
      else
      {
        if (params['id'] == '00') {
          // var v1 = Number(this.sharedFunctions.getLocalStorageValue(LocalStorageKeys.TenantId));
          // if (v1 != null && v1 > 0) {
          //   this.tenantId = v1;
          //   this.loadData();
          // }
        }
        else {
          if (this.canAdd) {
            this.item = new Tenant(null, null, null, null, null, null, null, null, null, true);
            this.formGroup.patchValue(this.item);
          }
          else
            this.msgError = "Item no válido.";
        }
      }
    });
  }

  loadData() {
    if (this.canView || this.canEdit) {
      this.isSearching = true;
      this.tenantService.get(this.tenantId).subscribe(data => {
        this.isSearching = false;
        if (data != null && data.Success) {
          this.item = data.Result;

          if (this.item != null && this.item.Id > 0) {
            if (this.canEdit || this.canAdminEdit)
              this.formGroup.patchValue(this.item);
          }
          else
            this.msgError = "No se encontró el tenant seleccionado.";
        }
        else
          this.msgError = "Ocurrió un error al intentar obtener los datos del tenant.";
      },
      (err: HttpErrorResponse) => {
        this.isSearching = false;
        this.sharedFunctions.handleError(err, true);
      })
    }
    else {
      this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
      this.onCancel();
    }
  }

  getErrorNombre() {
    var v = this.formGroup.get('Nombre');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  tenantImageChanged() {
    return (this.tenantImageBase64 != null);
  }

  onGuardar() {

    if (!this.formGroup.valid) {
      this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
      return false;
    }

    var nombreCorto = this.formGroup.get("NombreCorto").value;
    if (nombreCorto == null || nombreCorto.length == 0)
      this.formGroup.get("NombreCorto").setValue(this.formGroup.get("Nombre").value);

    var index = this.tenantImageBase64?.toString()?.indexOf(",");
    var r = new SaveTenantDto(null, null, null, null, null, null, null, null, null, null, null);

    r = 
    {
      ...this.formGroup.value,
      ...
      {
        Id: (this.item?.Id ?? 0),
        FotoLogoBase64: (index != null && index >= 0 ? this.tenantImageBase64?.toString()?.substring(index+1) : null),
        FotoLogoExtension: this.tenantImageExtension,
        Activo: (this.item?.Activo ?? true)
      } 
    };

    this.isSaving = true;
    this.tenantService.save(r).subscribe(data => {
      this.isSaving = false;
      if (data != null && data.Success && data.Result != null && data.Result.Id > 0) {
        this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
        this.onCancel();
      }
      else
        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
    },
    (err: HttpErrorResponse) => {
      this.isSaving = false;
      this.sharedFunctions.handleError(err, true);
    })
  }

  onCancel() {
    this.location.back();
  }
}
