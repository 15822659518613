<mat-card>
  <mat-card-header>
    <mat-card-title>Gestión de Persona</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>

    <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
    <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
    <mat-label *ngIf="!isSearching && msgError?.length > 0">{{msgError}}</mat-label>

    <div *ngIf="!isSearching && persona != null">

      <mat-expansion-panel [expanded]="true">

        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-top: -3px;">person</mat-icon> Datos de Persona
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <form [formGroup]="personaFormGroup" *ngIf="canAdd || canEdit">

          <div fxLayout="row stretch" fxLayout.xs="column" class="zb-readonly">

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nombre*</mat-label>
                <input matInput formControlName="Nombre" maxlength="150" (keyup.enter)="onGuardarPersona()">
                <mat-error *ngIf="!personaFormGroup.controls['Nombre'].valid && personaFormGroup.controls['Nombre'].touched">
                  {{ getErrorNombre() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Apellido*</mat-label>
                <input matInput formControlName="Apellido" maxlength="150" (keyup.enter)="onGuardarPersona()">
                <mat-error *ngIf="!personaFormGroup.controls['Apellido'].valid && personaFormGroup.controls['Apellido'].touched">
                  {{ getErrorApellido() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label *ngIf="!isTipoDocumentoSearching">Tipo Documento</mat-label>
                <mat-label *ngIf="isTipoDocumentoSearching">Cargando...</mat-label>
                <mat-select formControlName="TipoDocumentoId" (selectionChange)="onTipoDocumentoSelection()">
                  <mat-option>Ninguno</mat-option>
                  <mat-option *ngFor="let s of tipoDocumentoList" [value]="s.Id">{{s.Nombre}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nro. Documento</mat-label>
                <input matInput formControlName="NroDocumento" type="text" (change)="onNroDocumentoChange()" maxlength="100" (keyup.enter)="onGuardarPersona()">
                <mat-error *ngIf="!personaFormGroup.controls['NroDocumento'].valid && personaFormGroup.controls['NroDocumento'].touched">
                  {{ getErrorNroDocumento() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%" >
                <mat-label>Fecha Nacimiento</mat-label>
                <input matInput [matDatepicker]="fechaNacimientoPicker" formControlName="FechaNacimiento" readonly (click)="fechaNacimientoPicker.open()">
                <mat-datepicker-toggle style="display:inline-block;" matSuffix [for]="fechaNacimientoPicker">
                </mat-datepicker-toggle>
                <mat-datepicker-toggle style="display:inline-block;" matSuffix (click)="clearDate('FechaNacimiento')">
                  <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #fechaNacimientoPicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Mail de Contacto</mat-label>
                <input matInput formControlName="MailContacto" (keyup.enter)="onGuardarPersona()" maxlength="255">
                 <mat-error *ngIf="!personaFormGroup.controls['MailContacto'].valid && personaFormGroup.controls['MailContacto'].touched">
                  {{ getErrorMailContacto() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item" style="margin-top: 20px;">
              <mat-checkbox [(ngModel)]="persona.Activo" [ngModelOptions]="{standalone: true}" (keyup.enter)="onGuardarPersona()" color="primary">Activo</mat-checkbox> 
            </div>

          </div>

        </form>

        <ng-container *ngIf="!canAdd && !canEdit">

          <div fxLayout="row stretch" class="zb-readonly">

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Nombre</b>: {{persona.Nombre}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Apellido</b>: {{persona.Apellido}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Tipo Doc.</b>: {{persona.TipoDocumentoNombre}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Nro. Doc.</b>: {{persona.NroDocumento}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Fecha Nacimiento</b>: {{persona.FechaNacimiento | date:'dd/MM/yyyy'}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Mail de Contacto</b>: {{persona.MailContacto}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Activo</b>: {{persona.Activo ? 'Sí' : 'No'}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

          </div>

        </ng-container>

        <hr style="margin:20px 0px; width: 100%;">

        <div fxLayout="row stretch" fxLayout.xs="column" class="zb-readonly">

          <div fxFlex=100 fxFlex.xs=100 class="flex-item" fxLayoutAlign="center" style="margin-bottom: 15px;">
            <mat-label><u><b>Datos Biométricos</b></u></mat-label>
          </div>

          <div fxFlex=33 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true" >
              <div fxLayout="row stretch" fxLayout.xs="column">
                <a href="{{persona?.FotoDniFrenteUrl}}" target="_blank" *ngIf="hasPersonaFotoDniFrente()">Ver Foto DNI Frente</a>
                <mat-label *ngIf="!hasPersonaFotoDniFrente()">
                  <b><i class="fa fa-exclamation-triangle" style="margin-right: 5px;"></i> No tiene imagen de DNI frente</b>
                </mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <br/>
          <div fxFlex=33 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <a href="{{persona?.FotoDniDorsoUrl}}" target="_blank" *ngIf="hasPersonaFotoDniDorso()">Ver Foto DNI Dorso</a>
                <mat-label *ngIf="!hasPersonaFotoDniDorso()">
                  <b><i class="fa fa-exclamation-triangle" style="margin-right: 5px;"></i> No tiene imagen de DNI dorso</b>
                </mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <br/>
          <div fxFlex=33 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <a href="{{persona?.FotoSelfieUrl}}" target="_blank" *ngIf="hasPersonaFotoSelfie()">Ver Foto Selfie</a>
                <mat-label *ngIf="!hasPersonaFotoSelfie()">
                  <b><i class="fa fa-exclamation-triangle" style="margin-right: 5px;"></i> No tiene imagen de selfie</b>
                </mat-label>
              </div>
            </mat-expansion-panel>
          </div>

        </div>

      </mat-expansion-panel>

      <ng-container *ngIf="puedeVerUsuario && persona != null && persona.Id > 0">

        <hr style="margin:20px 0px; width: 100%;">

        <mat-expansion-panel [expanded]="false">

          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon style="margin-top: -3px;">supervisor_account</mat-icon> Usuarios Asociados
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div fxLayout="row stretch" fxLayout.xs="column">

            <mat-label *ngIf="isUsuariosAsociadosSearching">Buscando...</mat-label>
            <mat-label *ngIf="!isUsuariosAsociadosSearching && usuariosAsociadosError.length > 0">{{usuariosAsociadosError}}</mat-label>

            <ejs-grid #grid [dataSource]='usuariosAsociadosList' [allowPaging]="true" [allowSorting]="true"
              *ngIf="!isUsuariosAsociadosSearching" [selectionSettings]='selectionOptions' [pageSettings]='pageSettings'
              [allowResizing]='true'>
              <e-columns>
                <e-column field='Login' headerText='Usuario' width=90></e-column>
                <e-column field='Estado' headerText='Estado' width=90></e-column>
                <e-column field='Mail' headerText='Mail' width=90></e-column>
                <e-column field='Activo' headerText='Activo' width=90>
                  <ng-template #template let-data>
                    {{data.Activo ? 'Sí' : 'No'}}
                  </ng-template>
                </e-column>
                <e-column field='Bloqueado' headerText='Bloqueado' width=90>
                  <ng-template #template let-data>
                    {{data.Bloqueado ? 'Sí' : 'No'}}
                  </ng-template>
                </e-column>
                <e-column headerText='Acciones' width=90 *ngIf="puedeVerUsuario">
                  <ng-template #template let-data>
                    <a style="cursor: pointer; margin-right: 5px" (click)="editUser(data)"><i class="fa fa-eye fa-1x"></i></a>
                  </ng-template>
                </e-column>
              </e-columns>
            </ejs-grid>

          </div>

        </mat-expansion-panel>

      </ng-container>

    </div>

  </mat-card-content>
 
  <mat-card-actions style="margin-bottom: 20px;">
    <div fxLayout="row stretch" fxLayout.xs="column">
      <button mat-raised-button (click)="onGuardarPersona()" *ngIf="canAdd || canEdit" [disabled]="isProcessing()"
        color="primary" class="zb-button">{{isPersonSaving ? 'Guardando...' : 'Guardar'}}</button>
      <button mat-raised-button (click)="onCancel()" color="warn" class="zb-button">{{canAdd || canEdit ? 'Cancelar' : 'Cerrar'}}</button>
    </div>
  </mat-card-actions>

</mat-card>