import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { CrudService } from "./crud.service";
import { GenericResponse, Presupuesto, SearchPresupuestoRequest, SavePresupuestoDto, PublicCalculateRequestDto, ServicioCompraRequest } from "../model/index";
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})
export class PresupuestoService extends CrudService<Presupuesto, number, SavePresupuestoDto, SearchPresupuestoRequest> {

  constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
    super(_http,'presupuesto', sharedFunctions);

  }

  calculate(request: PublicCalculateRequestDto): Observable<GenericResponse<any>> {
    var parameters = this.sharedFunctions.convertToQueryString(request);
    return this._http.get<GenericResponse<any>>(this._serviceUrl + '/calculate'+parameters, this.httpOptions);
  }

  calculateServicioCompramosPorVos(request: ServicioCompraRequest): Observable<GenericResponse<any>> {
    var parameters = this.sharedFunctions.convertToQueryString(request);
    return this._http.get<GenericResponse<any>>(this._serviceUrl + '/calculate-servicio-compramos-por-vos'+parameters, this.httpOptions);
  }

  deleteItem(itemId: number): Observable<GenericResponse<any>> {
    return this._http.delete<GenericResponse<any>>(this._serviceUrl + '/delete-item/'+itemId, this.httpOptions);
  }
}