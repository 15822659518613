import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { CrudService } from "./crud.service";
import { GenericResponse, Tenant, SearchTenantRequest, SearchTenantResponse, SaveTenantDto } from "../model/index";
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})
export class TenantService extends CrudService<Tenant, number, SaveTenantDto, SearchTenantRequest> {

  constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
    super(_http,'tenant', sharedFunctions);

  }

  getAnonymousById(id: string): Observable<GenericResponse<Tenant>> {
    return this._http.get<GenericResponse<Tenant>>(this._serviceUrl+"/anonymous/"+id, this.httpOptions);
  }

  getAnonymousList(): Observable<GenericResponse<Tenant[]>> {
    return this._http.get<GenericResponse<Tenant[]>>(this._serviceUrl+"/anonymous", this.httpOptions);
  }
}