import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CrudService } from '.';
import { GenericResponse, Persona, SavePersonVerificationImageRequestDto, SavePersonaDto, SearchPersonaRequestDto } from "../model/index";
import { SharedFunctions } from '../../shared/shared.functions';

@Injectable({
    providedIn: 'root'
})

export class PersonaService extends CrudService<Persona, number, SavePersonaDto, SearchPersonaRequestDto> {

    constructor(protected _http: HttpClient, private datePipe: DatePipe, protected sharedFunctions: SharedFunctions) {
        super(_http, 'persona', sharedFunctions);
    }

    getSelf(): Observable<GenericResponse<Persona>> {
        return this._http.get<GenericResponse<Persona>>(this._serviceUrl + '/self', this.httpOptions);
    }

    saveSelf(p: SavePersonaDto): Observable<GenericResponse<SavePersonaDto>> {
        return this._http.post<GenericResponse<SavePersonaDto>>(this._serviceUrl + '/save/self', p, this.httpOptions);
    }

    saveVerificationImagen(p: SavePersonVerificationImageRequestDto): Observable<GenericResponse<string>> {
        return this._http.post<GenericResponse<string>>(this._serviceUrl + '/save-verification-image', p, this.httpOptions);
    }
}

