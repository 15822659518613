export class CodigoPromocional {
  constructor(
    public readonly Id: number,
    public readonly Codigo: string,
    public readonly Porcentaje: number,
    public readonly Activo: boolean
  ) {}
}

export class SearchCodigoPromocionalRequest {
  constructor(
    public readonly PageSize: number | null,
    public readonly PageNumber: number | null,
    public readonly OrderAscending: boolean | null,
    public readonly OrderFieldName: string,
    public readonly Codigo: string,
    public readonly Porcentaje: number,
    public readonly VerSoloActivos: boolean | null
  ) {}
}
export class SaveCodigoPromocionalDto {
  constructor(
    public readonly Codigo: string,
    public readonly Porcentaje: number,
    public Activo: boolean
  ) {}
}

export class SearchCodigoPromocionalResponse {
  constructor(
    public readonly count: number,
    public readonly result: CodigoPromocional[]
  ) {}
}
