<mat-card>
  <mat-card-header>
    <mat-card-title>Gestión de Menú</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
 </mat-card-header>

  <mat-card-content>

    <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
    <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
    <mat-label *ngIf="!isSearching && msgError?.length > 0">{{msgError}}</mat-label>

    <div *ngIf="!isSearching && item != null">

      <ng-container *ngIf="canAdd || canEdit">

        <form [formGroup]="formGroup">

          <div fxLayout="row stretch" fxLayout.xs="column">

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Código*</mat-label>
                <input matInput formControlName="Codigo" (keyup.enter)="onGuardar()" maxlength="100">
                <mat-error *ngIf="!formGroup.controls['Codigo'].valid && formGroup.controls['Codigo'].touched">
                  {{ getErrorCodigo() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nombre*</mat-label>
                <input matInput formControlName="Nombre" (keyup.enter)="onGuardar()" maxlength="150"> 
                <mat-error *ngIf="!formGroup.controls['Nombre'].valid && formGroup.controls['Nombre'].touched">
                  {{ getErrorNombre() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Orden*</mat-label>
                <input matInput type="number" formControlName="Orden" (keyup.enter)="onGuardar()">
                <mat-error *ngIf="!formGroup.controls['Orden'].valid && formGroup.controls['Orden'].touched">
                  {{ getErrorOrden() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Link</mat-label>
                <input matInput formControlName="Link" (keyup.enter)="onGuardar()" maxlength="100">
                <mat-error *ngIf="!formGroup.controls['Link'].valid && formGroup.controls['Link'].touched">
                  {{ getErrorLink() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Icono</mat-label>
                <input matInput formControlName="Icono" (keyup.enter)="onGuardar()" maxlength="100">
                <mat-error *ngIf="!formGroup.controls['Icono'].valid && formGroup.controls['Icono'].touched">
                  {{ getErrorIcono() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label></mat-label>
                <mat-label *ngIf="!isMenuPadreSearching">Menú Padre</mat-label>
                <mat-label *ngIf="isMenuPadreSearching">Cargando...</mat-label>
                <mat-select formControlName="MenuPadreId">
                  <mat-option>Ninguno</mat-option>
                  <mat-option *ngFor="let s of menuPadreList" [value]="s.Id">{{s.Nombre}} ({{s.Codigo}})</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label></mat-label>
                <mat-label *ngIf="!isTipoRolSearching">Máx. Tipo de Rol</mat-label>
                <mat-label *ngIf="isTipoRolSearching">Cargando...</mat-label>
                <mat-select formControlName="MaxRolTipoId">
                  <mat-option *ngFor="let s of rolTipoList" [value]="s.Id">{{s.Nombre}}</mat-option>
                </mat-select>
                <mat-error *ngIf="!formGroup.controls['MaxRolTipoId'].valid && formGroup.controls['MaxRolTipoId'].touched">
                  {{ getErrorMaxRolTipoId() }}
                </mat-error>
              </mat-form-field>
            </div>

          </div>

        </form>

        <div fxLayout="row stretch" fxLayout.xs="column">
          <div fxFlex=33.33 fxFlex.xs=100 class="flex-item">
            <mat-checkbox [(ngModel)]="item.Visible" (keyup.enter)="onGuardar()" color="primary">Visible</mat-checkbox> 
          </div>
          <div fxFlex=33.33 fxFlex.xs=100 class="flex-item">
            <mat-checkbox [(ngModel)]="item.Activo" (keyup.enter)="onGuardar()" color="primary">Activo</mat-checkbox> 
          </div>
        </div>
        
      </ng-container>

      <ng-container *ngIf="!canAdd && !canEdit">

        <div fxLayout="row stretch" class="zb-readonly">
    
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Código</b>: {{item.Codigo}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Nombre</b>: {{item.Nombre}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Orden</b>: {{item.Orden}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Link</b>: {{item.Link}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b><a href="https://fontawesome.com/v4.7/icons/" target="_blank" title="Navegar a web de iconos">Icono</a></b>: {{item.Icono}} <i style="margin-left: 5px;" class="fa {{item.Icono}} fa-1x" title="Icono seleccionado"></i></mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Menú Padre</b>:
                  {{item.MenuPadre != null ? item.MenuPadre?.Nombre + ' (' + item.MenuPadre?.Codigo + ')' : ''}}
                  <a *ngIf="item.MenuPadre != null" style="margin-left: 5px; cursor: pointer;" (click)="navigateMenuPadre(item.MenuPadre)" title="Ver Menú Padre"><i class="fa fa-eye fa-1x"></i></a>
                </mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Máx. Tipo de Rol</b>: {{item.MaxRolTipo?.Nombre}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
          </div>

          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Visible</b>: {{item.Visible ? 'Sí' : 'No'}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Activo</b>: {{item.Activo ? 'Sí' : 'No'}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

        </div>

      </ng-container>

    </div>

  </mat-card-content>

  <mat-card-actions style="margin-bottom: 20px;">
    <div fxLayout="row stretch" fxLayout.xs="column">
      <button mat-raised-button (click)="onGuardar()" *ngIf="canAdd || canEdit" [disabled]="isProcessing()" color="primary" class="zb-button">{{isSaving ? 'Guardando...' : 'Guardar'}}</button>
      <button mat-raised-button (click)="onCancel()" color="warn" class="zb-button">{{canAdd || canEdit ? 'Cancelar' : 'Cerrar'}}</button>
      <button mat-raised-button (click)="onDelete()" [disabled]="isProcessing()" *ngIf="canDelete" color="accent" class="zb-button">{{isDeleting ? 'Eliminando...' : 'Eliminar'}}</button>
    </div>
  </mat-card-actions>

</mat-card>