<mat-card>
  <mat-card-header>
    <mat-card-title>Búsqueda de Cotizaciones</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px;">
      <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
    </div>

    <ng-container *ngIf="canView">

      <mat-expansion-panel *ngIf="!isValidating">

        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-top: -3px;">search</mat-icon> Filtros de Búsqueda
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <form [formGroup]="searchForm">
          <div fxLayout="row stretch" fxLayout.xs="column">
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Fecha Cotización Desde</mat-label>
                <input matInput [matDatepicker]="fechaCotizacionDesdePicker" formControlName="FechaCotizacionDesde" readonly (click)="fechaCotizacionDesdePicker.open()">
                <mat-datepicker-toggle style="display:inline-block;" matSuffix [for]="fechaCotizacionDesdePicker">
                </mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix (click)="clearDate('FechaCotizacionDesde')">
                  <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #fechaCotizacionDesdePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Fecha Cotización Hasta</mat-label>
                <input matInput [matDatepicker]="fechaCotizacionHastaPicker" formControlName="FechaCotizacionHasta" readonly (click)="fechaCotizacionHastaPicker.open()">
                <mat-datepicker-toggle style="display:inline-block;" matSuffix [for]="fechaCotizacionHastaPicker">
                </mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix (click)="clearDate('FechaCotizacionHasta')">
                  <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #fechaCotizacionHastaPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </form>

        <div fxLayout="row stretch" fxLayout.xs="column">
          <mat-checkbox [(ngModel)]="verSoloActivos" (keyup.enter)="onSearch()" color="primary">Ver Solo Activos</mat-checkbox> 
        </div>

      </mat-expansion-panel>

      <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px">
        <button mat-raised-button (click)="add()" *ngIf="canAdd" [disabled]="isSearching" color="primary" class="zb-button">Agregar</button>
        <button mat-raised-button (click)="onSearch()" [disabled]="isSearching" color="primary" class="zb-button">{{isSearching ? 'Buscando...' : 'Buscar'}}</button>
        <button mat-raised-button (click)="onExport()" [disabled]="isExporting" color="primary" class="zb-button">{{isExporting ? 'Exportando...' : 'Exportar'}}</button>
      </div>

      <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px;">
        <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
      </div>

      <div fxLayout="row stretch" fxLayout.xs="column" *ngIf="!isSearching">
        <ejs-grid #grid [dataSource]='data' [query]='query' [allowPaging]="true" [allowSorting]="true" [toolbar]='toolbarOptions' [selectionSettings]='selectionOptions' 
          [pageSettings]='pageSettings' [allowResizing]='true' (actionFailure)="onGridActionFailure($event)">
          <e-columns>
            <e-column field='FechaCotizacion' headerText='Fecha Cotización' type="Date" format="dd/MM/yyyy" width=90></e-column>
            <e-column field='ValorCompraOficial' headerText='Compra Oficial' width=90 format="N2"></e-column>
            <e-column field='ValorVentaOficial' headerText='Venta Oficial' width=90 format="N2"></e-column>
            <e-column field='ValorCompraTarjeta' headerText='Compra Tarjeta' width=90 format="N2"></e-column>
            <e-column field='ValorVentaTarjeta' headerText='Venta Tarjeta' width=90 format="N2"></e-column>
            <e-column field='Activo' headerText='Activo?' width=90>
              <ng-template #template let-data>
                {{data.Activo ? 'Sí' : 'No'}}
              </ng-template>
            </e-column>
            <e-column headerText='Acciones' width=90>
              <ng-template #template let-data>
                <a style="cursor: pointer; margin-right: 5px" (click)="edit(data)"><i class="fa fa-eye fa-1x"></i></a>
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>

    </ng-container>

  </mat-card-content>
</mat-card>