import { Component, OnInit, ViewChild } from '@angular/core';
import { DataManager, WebApiAdaptor, Query, ReturnOption } from '@syncfusion/ej2-data';
import { Column, ExcelExportProperties, GridComponent, PageSettingsModel, SelectionSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClickEventArgs } from '@syncfusion/ej2-buttons';
import { DatePipe } from '@angular/common';

import { SharedFunctions } from 'src/app/shared/shared.functions';
import { LocalStorageKeys, Persona, SearchPersonaRequestDto, SearchTipoDocumentoRequest, TipoDocumento } from 'src/app/shared/model';

import { MenuFuncionService, PersonaService, TipoDocumentoService } from 'src/app/shared/services/index';
import { GridPageSettings, MenuFuncionCodes } from 'src/app/shared/model';

@Component({
  selector: 'app-personaLista',
  templateUrl: './personaLista.component.html',
})

export class PersonaListaComponent implements OnInit {
  @ViewChild('grid')
  public grid: GridComponent;

  public data: DataManager;
  total: number = 0;
  searchForm: FormGroup;
  isValidating: boolean = false;
  isSearching: boolean = false;
  isExporting: boolean = false;
  isTipoDocumentoSearching: boolean = false;

  canAdd: boolean = false;
  canView: boolean = false;
  verSoloConUsuarios: boolean = false;
  verSoloActivos: boolean = false;

  tipoDocumentoList: TipoDocumento[];

  public pageSizes: number[];
  public pageSettings: PageSettingsModel;
  public query: Query;

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private sharedFunctions: SharedFunctions,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuFuncionService,
    private tipoDocumentoService: TipoDocumentoService,
    private service: PersonaService) {

  }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuService.checkMenuPadre(MenuFuncionCodes.PERSONA).subscribe(data => {
      this.isValidating = false;
      if (data != null && data.Success && data?.Result){
        this.canAdd = data.Result.some(x => x == MenuFuncionCodes.PERSONA_ADD);
        this.canView = data.Result.some(x => x == MenuFuncionCodes.PERSONA_LIST);
          if (this.canView){
            this.loadView();
          } else {
            this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
            this.router.navigate(['/']);
          }
      }
      else {
        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
        this.router.navigate(['/']);
      }
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    this.canView = true;
    this.pageSizes = GridPageSettings.PageSizeList;
    this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[0], pageSizes: this.pageSizes };

    this.buildForm();
    
    this.setQueryParams();
    this.data = new DataManager({
      url: this.service.getSearchUrl(),
      adaptor: new WebApiAdaptor(),
      headers: [this.service.getAuthorizationHeader()]
    });

    this.getTiposDocumento();
  }

  getTiposDocumento() {
    var r = new SearchTipoDocumentoRequest(null, null, true, "Nombre", null, null, true);
    this.isTipoDocumentoSearching = true;

    this.tipoDocumentoService.search(r).subscribe(data => {
      this.isTipoDocumentoSearching = false;
      if (data != null) {
        this.tipoDocumentoList = data?.result;
      }
    },
    (err: HttpErrorResponse) => {
      this.isTipoDocumentoSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  buildForm() {
    this.searchForm = this.formBuilder.group({
      'Nombre': [null],
      'Apellido': [null],
      'NroDocumento': [null],
      'MailContacto': [null],
      'Movil': [null]
    });
  }

  setQueryParams() {
    if (this.query == null)
      this.query = new Query();

    var index = this.query.params.findIndex(obj => obj.key === "Nombre");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.Nombre;
    else
      this.query.addParams("Nombre", this.searchForm.value.Nombre);

    index = this.query.params.findIndex(obj => obj.key === "Apellido");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.Apellido;
    else
      this.query.addParams("Apellido", this.searchForm.value.Apellido);

    index = this.query.params.findIndex(obj => obj.key === "MailContacto");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.MailContacto;
    else
      this.query.addParams("MailContacto", this.searchForm.value.MailContacto);

    index = this.query.params.findIndex(obj => obj.key === "Movil");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.Movil;
    else
      this.query.addParams("Movil", this.searchForm.value.Movil);

    index = this.query.params.findIndex(obj => obj.key === "NroDocumento");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.NroDocumento;
    else
      this.query.addParams("NroDocumento", this.searchForm.value.NroDocumento);

    index = this.query.params.findIndex(obj => obj.key === "VerSoloActivos");
    if (index >= 0)
      this.query.params[index].value = this.verSoloActivos ? "true" : "false";
    else
      this.query.addParams("VerSoloActivos", this.verSoloActivos ? "true" : "false");

    index = this.query.params.findIndex(obj => obj.key === "VerSoloConUsuarios");
    if (index >= 0)
      this.query.params[index].value = this.verSoloConUsuarios ? "true" : "false";
    else
      this.query.addParams("VerSoloConUsuarios", this.verSoloConUsuarios ? "true" : "false");
  }

  onGridActionFailure(event: any): void {
    this.sharedFunctions.showMessage("Ocurrió un error intentando realizar la búsqueda", "Error");
  }

  onSearch() {
    this.setQueryParams();
    this.grid.refresh();
  }

  add() {
    if (this.canAdd) {
      this.router.navigate(['persona-edit', 0]);
    }
    else {
      this.sharedFunctions.showMessage("No tiene permisos para crear una nueva persona", "Error");
    }
  }

  edit(item: Persona) {
    if (!item || item.Id == null || item.Id <= 0) {
      this.sharedFunctions.showMessage("Debe seleccionar un item", "Error");
      return false;
    }

    this.router.navigate(['persona-edit', item.Id]);
  }

  onExport() {
    var search = new SearchPersonaRequestDto(null, null, null, "", null, null, null, null, this.verSoloActivos, this.verSoloConUsuarios);
    search = Object.assign(search, this.searchForm.value);

    this.isExporting = true;
    this.service.exportSearch(search).subscribe((data: Blob) => {
      this.isExporting = false;
      var fileUrl = URL.createObjectURL(data);

      var anchor = document.createElement("a");
      anchor.download = "Personas_" + this.datePipe.transform(new Date(), "yyyy-MM-dd") + ".csv";
      anchor.href = fileUrl;
      anchor.click();
    },
      (err: HttpErrorResponse) => {
        this.isExporting = false;
        return this.sharedFunctions.handleError(err);
      })
  }
}
