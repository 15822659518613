import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { debounceTime, distinctUntilChanged, filter, finalize, startWith, switchMap, tap } from 'rxjs/operators';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { PresupuestoService, ProductoService, TenantService, DestinoEnvioService, OrigenService, ParametroSistemaService } from '../shared/services';
import { DestinoList, OrigenEnum, Producto, PublicCalculateRequestDto, ServicioCompraRequest, Tenant } from 'src/app/shared/model';

@Component({
  selector: 'app-calculadoraPublic',
  templateUrl: './calculadoraPublic.component.html',
  styleUrls: ['./calculadoraPublic.component.scss']
})
export class CalculadoraPublicComponent implements OnInit {

  valorImportacion: number | null = null;
  pesoImportacion: number | null = null;
  categoriaProducto: string | null = null;
  origen: string | null = null;
  destino: string | null = null;
  codigoPromocional: string | null = null;
  porcentajeDolarOficial: string = "";

  encryptedTenantId: string = "";
  tenant: Tenant;

  destinos: DestinoList[] = [];
  selectedDestinoNombre: string;
  origenes: any[] = [];
  productos: any[] = [];
  filteredProductos: any[]; //Observable<any[]>;
  searchProducto: string = "";
  productoSearchControl = new FormControl();

  selectedProducto: Producto | null = null;
  selectedProductoId: number | null = null;
  selectedOrigenId: number | null = null;
  selectedDestinoId: number | null = null;

  sessionId: string;
  resultadoCalculo: any = null;
  resultadoServicio: any = null;

  isCalculandoEnvio: boolean = false;
  isAgregandoItem: boolean = false;
  isCalculandoServicioCompra: boolean = false;
  isDestinoEnvioSearching: boolean = false;
  isOrigenSearching: boolean = false;
  isProductoSearching: boolean = false;
  origenEnum = OrigenEnum;

  valorImportacionHelpText: string = "Valor del producto: para envíos particulares el máximo permitido es de 3 unidades por producto, con un valor máximo de compra de hasta USD 1000.";
  pesoImportacionHelpText: string = "Peso del producto: el costo mínimo para cotizar es 1kg y a partir del kg, se redondea de 500 en 500 gramos.";
  origenImportacionHelpText: string = "Origen: tenés que poner un solo origen. No se pueden hacer dos cotizaciones con origen distinto.";
  productoImportacionHelpText: string = "Producto: busca el producto que quieras importar.";
  destinoImportacionHelpText: string = "Destino: es el costo por el traslado nacional, desde nuestro depósito hasta tu domicilio.";
  codigoPromocionalHelpText: string = "Código promocional: no es obligatorio llenar este campo, lo podés dejar en blanco en caso que no cuentes con código de promoción.";

  constructor(
    private formBuilder: FormBuilder, 
    public dialog: MatDialog,
    private sharedFunctions: SharedFunctions,
    private route: ActivatedRoute,
    private tenantService: TenantService,
    private destinoEnvioService: DestinoEnvioService,
    private parametroSistemaService: ParametroSistemaService,
    private origenService: OrigenService,
    private productoService: ProductoService,
    private presupuestoService: PresupuestoService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.encryptedTenantId = params['t'];
      this.loadView();
    });
  }

  loadView() {
    this.nuevoPresupuesto();
    this.getPorcentajeDolarOficial();

    this.tenantService.getAnonymousById(this.encryptedTenantId).subscribe((data: any) => {
      if (data && data.Success)
        this.tenant = data.Result;
    },
    (error) => {
    });

    this.isDestinoEnvioSearching = true;
    this.destinoEnvioService.getAnonymous(this.encryptedTenantId).subscribe((data: any) => {
      this.isDestinoEnvioSearching = false;
        if (data && data.Success)
          this.destinos = data.Result;
      },
      (error) => {
        this.isDestinoEnvioSearching = false;
      }
    );

    this.isOrigenSearching = true;
    this.origenService.getAnonymous(this.encryptedTenantId).subscribe((data: any) => {
      this.isOrigenSearching = false;
        if (data && data.Success)
          this.origenes = data.Result;
          this.selectedOrigenId = (this.origenes.find(origen => origen.Id == OrigenEnum.Miami))?.Id;
      },
      (error) => {
        this.isOrigenSearching = false;
      }
    );

    this.isProductoSearching = true;
    this.productoService.getAnonymous(this.encryptedTenantId).subscribe((data: any) => {
      this.isProductoSearching = false;
        if (data && data.Success) {
          this.productos = data.Result;
          this.filteredProductos = data.Result;
        }
      },
      (error) => {
        this.isProductoSearching = false;
      }
    );

    this.productoSearchControl.valueChanges
    .pipe(
      // filter(res => {
      //   return res && res !== null && res.length >= 2
      // }),
      distinctUntilChanged(),
      // debounceTime(500),
      tap(() => {
        this.filteredProductos = [];
        // this.isProveedorAutoSearching = true;
      }),
      switchMap((value: string) => this._filterProductos(value))
        // .pipe(
        //   finalize(() => {
        //     // this.isProveedorAutoSearching = false;
        //   }),
        // )
      )
      .subscribe(data => {
        // this.isProveedorAutoSearching = false;
        this.filteredProductos.push(data);
      });
  }

  private _filterProductos(value: string) {
    if (typeof(value) == "string") {
      var r = this.productos.filter(option => option.Nombre?.toLowerCase().includes(value?.toLowerCase()));
      return r;
    }

    return this.productos;
  }

  getPorcentajeDolarOficial() {
    this.parametroSistemaService.getAnonymousCode('PorcentajeDolarOficial').subscribe((data: any) => {
      if (data && data.Success)
        this.porcentajeDolarOficial = data.Result.Valor;
    });  
  }

  onProductoSelect() {
    this.selectedProducto = this.productoSearchControl.value;
  }

  openAyudaModal(texto: string) {
    this.sharedFunctions.showMessage(texto, "Ayuda");
  }
  
  private setNewCookie() {
    this.sessionId = this.generateSessionId();
    this.setCookie('sessionId', this.sessionId);
  }

  private generateSessionId(): string {
    const timestamp = new Date().getTime().toString(16); // Obtén el timestamp en hexadecimal
    const randomPart = Math.floor(Math.random() * 1000000).toString(16); // Número aleatorio en hexadecimal
    return timestamp + '_' + randomPart;
  }

  private setCookie(name: string, value: string): void {
    document.cookie = name + '=' + value + '; path=/';
  }

  private cleanPresupuesto() {
    this.valorImportacion = null;
    this.pesoImportacion = null;
    this.categoriaProducto = null;
    this.origen = null;
    this.destino = null;
    this.codigoPromocional = null;

    this.selectedProducto = null;
    this.selectedProductoId = null;
    this.selectedOrigenId = null;
    this.selectedDestinoId = null;

    this.productoSearchControl.reset();
    this.filteredProductos = this.productos;
  }

  public nuevoPresupuesto() {
    this.setNewCookie();
    this.cleanPresupuesto();
    
    this.resultadoCalculo = null;
    this.resultadoServicio = null;
  }

  displayProducto(producto: Producto): string {
    return producto && producto?.Nombre ? producto.Nombre : '';
  }

  get valorImportacionValido(): boolean {
    return this.valorImportacion !== null && this.valorImportacion > 0;
  }

  get pesoImportacionValido(): boolean {
    return this.pesoImportacion !== null && this.pesoImportacion > 0;
  }

  get categoriaProductoValida(): boolean {
    return this.selectedProducto?.Id !== null;
  }

  get origenValido(): boolean {
    return this.selectedOrigenId !== null;
  }

  get destinoValido(): boolean {
    return this.selectedDestinoId !== null;
  }

  validarCampos(): boolean {
    return (this.valorImportacionValido && 
      this.pesoImportacionValido &&
      this.categoriaProductoValida && 
      this.origenValido && 
      this.destinoValido);
  }

  servicioCompra() {
    if (this.isCalculandoServicioCompra)
      return false;

    const request: ServicioCompraRequest = {
      SessionId: this.sessionId,
    };
  
    this.isCalculandoServicioCompra = true;
    this.presupuestoService.calculateServicioCompramosPorVos(request).subscribe((response: any) => {
      this.isCalculandoServicioCompra = false;
      if (response.Success) {
        this.resultadoServicio = response.Result;
        this.cleanPresupuesto();
      } 
      else {
        this.sharedFunctions.showMessage("Ocurrió un error intentando realizar el cálculo. Por favor intente nuevamente", "Error");
      }
    },
    (err: HttpErrorResponse) => {
      this.isCalculandoServicioCompra = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  calcularEnvio(): void {
    if (this.isCalculandoEnvio)
      return;

    if (this.isAgregandoItem)
      return;

    this.selectedProductoId = this.selectedProducto?.Id;

    const selectedDestino = this.destinos.find(destino => destino.Id === this.selectedDestinoId);
    this.selectedDestinoNombre = selectedDestino?.Nombre;

    if (this.validarCampos()) {
      const request: PublicCalculateRequestDto = {
        SessionId: this.sessionId,
        EncryptedTenantId: this.encryptedTenantId,
        ValorImportacion: this.valorImportacion!,
        PesoImportacion: this.pesoImportacion!,
        ProductoId: this.selectedProductoId,
        OrigenId: this.selectedOrigenId,
        DestinoEnvioId: this.selectedDestinoId,
        CodigoPromocional: this.codigoPromocional || ''
      };

      this.isCalculandoEnvio = true;
      this.isAgregandoItem = true;
      this.presupuestoService.calculate(request).subscribe((response: any) => {
        this.isCalculandoEnvio = false;
        this.isAgregandoItem = false;
        if (response.Success) {
          this.resultadoCalculo = response.Result;
          this.cleanPresupuesto();

          //Si ya calculo el servicio de compra, recalculo esa cuenta con el nuevo item agregado al presupuesto
          if (this.resultadoServicio != null)
            this.servicioCompra();

          setTimeout(() => {
            var gridResultados = document.getElementById("gridResultados");
            if (gridResultados != null)
              window.scrollTo({ top: gridResultados.offsetTop, behavior: 'smooth' });
          }, 200);
        }
        else
          this.sharedFunctions.showMessage("Ocurrió un error intentando realizar el cálculo. Por favor intente nuevamente", "Error");
      },
      (err: HttpErrorResponse) => {
        this.isCalculandoEnvio = false;
        this.isAgregandoItem = false;
        this.sharedFunctions.handleError(err, true);
      });
    }
    else
      this.sharedFunctions.showMessage("Hay algunos datos que no son válidos. Por favor corrijalos e intente nuevamente", "Error");
  }

  onItemDelete(producto: any) {
    if (producto == null || producto.Id == null || producto.Id <= 0) {
      this.sharedFunctions.showMessage("Debe seleccionar un item para continuar con la operación", "Error");
      return false;
    }

    if (producto.IsBorrandoItem)
      return false;

    if (this.resultadoCalculo.Productos != null && this.resultadoCalculo.Productos.some(f => f.IsBorrandoItem))
      return false;

    producto.IsBorrandoItem = true;
    this.presupuestoService.deleteItem(producto.Id).subscribe((response: any) => {
      producto.IsBorrandoItem = false;
      if (response.Success) {
        this.resultadoCalculo = response.Result;
        
        //Si ya calculo el servicio de compra, recalculo esa cuenta con el nuevo item agregado al presupuesto
        if (this.resultadoServicio != null)
          this.servicioCompra();
      }
      else
        this.sharedFunctions.showMessage("Ocurrió un error intentando realizar la baja del item. Por favor intente nuevamente", "Error");
    },
    (err: HttpErrorResponse) => {
      producto.IsBorrandoItem = false;
      this.sharedFunctions.handleError(err, true);
    });
  }
}
