import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { MenuFuncionService, MailConfiguracionService } from 'src/app/shared/services';
import { MenuFuncionCodes, ProveedorEnvioMailConstants, MailConfiguracion, SaveMailConfiguracionDto, MailProveedorEnvio, SendTestEmailDto, DialogTextoLibreRequest, DialogTextoLibreResponse, DialogConfirmRequest, DialogConfirmResponse, } from 'src/app/shared/model';

import { DialogTextoLibreComponent } from 'src/app/shared/components/modals/dialogTextoLibre.component';
import { DialogConfirmComponent } from 'src/app/shared/components/modals/dialogConfirm.component';

@Component({
  selector: 'app-mailConfiguracionEdit',
  templateUrl: './mailConfiguracionEdit.component.html',
  styleUrls: ['./mailConfiguration.component.scss']
})

export class MailConfiguracionEditComponent implements OnInit {
  formGroup: FormGroup;

  isValidating: boolean = false;
  isSearching: boolean = false;
  isSaving: boolean = false;
  isDeleting: boolean = false;
  isSendingTestMail: boolean = false;
  isProveedorEnvioSearching: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  canAdd: boolean = false;
  canView: boolean = false;
  hide: boolean = true;
  HabilitarSsl: boolean = true;

  msgError: string = "";
  item: MailConfiguracion;
  proveedorEnvioList: MailProveedorEnvio[];
  certificadoExternoUrl: any = "";
  certificadoExternoFileExtension: any = "";
  certificadoExternoFileName: any = "";
  readonly googleWorkSpaceId: number = 3;
  isFileAttaching: boolean;

  constructor (
    private formBuilder: FormBuilder,
    private location: Location,
    public dialog: MatDialog,
    private sharedFunctions: SharedFunctions,
    private route: ActivatedRoute,
    private menuService: MenuFuncionService,
    private mailConfiguracionService: MailConfiguracionService) {
  }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuService.checkMenuPadre(MenuFuncionCodes.MAIL_CONFIGURACION).subscribe(data => {
      if (data != null && data.Success && data?.Result) {
        this.isValidating = false;
        this.canEdit = data.Result.some(x => x == MenuFuncionCodes.MAIL_CONFIGURACION_EDIT);
        this.canAdd = data.Result.some(x => x == MenuFuncionCodes.MAIL_CONFIGURACION_ADD);
        this.canView = data.Result.some(x => x == MenuFuncionCodes.MAIL_CONFIGURACION_LIST);
        this.canDelete = data.Result.some(x => x == MenuFuncionCodes.MAIL_CONFIGURACION_DELETE);
        this.loadView();
      } else {
        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
        this.onCancel();        
      }      
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    this.buildForm();
    this.getProveedorEnvios()
    this.loadEntity();
  }

  getProveedorEnvios() {
    this.isProveedorEnvioSearching = true;
    this.mailConfiguracionService.getProveedoresEnvio().subscribe(data => {
      this.isProveedorEnvioSearching = false;
      if (data != null && data.Success)
        this.proveedorEnvioList = data.Result;
    },
    (err: HttpErrorResponse) => {
      this.isProveedorEnvioSearching = false;
      this.sharedFunctions.handleError(err);
    })
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      'Codigo': [null, [Validators.required, Validators.maxLength(100)]],
      'ProveedorEnvio': [null, Validators.required],
      'MailSaliente': [null, [Validators.required, Validators.maxLength(255)]],
      'MailSalienteDisplayName': [null, Validators.maxLength(255)],
      'MailResponderA': [null, Validators.maxLength(255)],
      'ServidorMail': [null, Validators.maxLength(300)],
      'PuertoServidorMail': [null],
      'Usuario': [null, Validators.maxLength(255)],
      'Clave': [null, Validators.maxLength(255)],
      'ClaveCertificadoExterno': [null, Validators.maxLength(255)],
      'Activo': [true, Validators.required]
    });
  }

  isProcessing() {
    return (this.isSearching || this.isSaving || this.isDeleting || this.isSendingTestMail);
  }

  loadEntity() {
    this.route.params.subscribe(params => {
      if (params['id'] != null && params['id'] != '0') {
        var id = Number(params['id']);

        if (id > 0) {
          if(this.canView || this.canEdit){
            this.isSearching = true;
            this.mailConfiguracionService.get(id).subscribe(data => {
              this.isSearching = false;
              if (data != null && data.Success) {
                this.item = data.Result;

                if (this.item != null && this.item.Id > 0) {
                  this.canDelete = this.canEdit;
                  if (this.canEdit)
                    this.formGroup.patchValue(this.item);
                  this.getFileNameAndExtension();
                }
                else
                  this.msgError = "No se encontró la configuración seleccionada.";
              }
              else
                this.msgError = "Ocurrió un error al intentar obtener los datos de la configuración.";
            },
            (err: HttpErrorResponse) => {
              this.isSearching = false;
              this.sharedFunctions.handleError(err, true);
            })
          } else{
            this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
            this.onCancel();    
          }
        }
        else
          this.msgError = "Item no válido.";
      }
      else {
        if (this.canAdd) {
          this.item = new MailConfiguracion(0, null, null, null, null, null, null, null, null, false, null, null, null, null, true);
          this.formGroup.patchValue(this.item);
        } 
        else {
          this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
          this.onCancel(); 
        }
      }
    });
  }

  getErrorCodigo() {
    var v = this.formGroup.get('Codigo');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorMailSaliente() {
    var v = this.formGroup.get('MailSaliente');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 255 caracteres alcanzado';
    return '';
  }

  getErrorMailSalienteDisplayName() {
    var v = this.formGroup.get('MailSalienteDisplayName');
    if (v.hasError('maxlength'))
      return 'Límite de 255 caracteres alcanzado';
    return '';
  }

  getErrorMailResponderA() {
    var v = this.formGroup.get('MailResponderA');
    if (v.hasError('maxlength'))
      return 'Límite de 255 caracteres alcanzado';
    return '';
  }

  getErrorServidorMail() {
    var v = this.formGroup.get('ServidorMail');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 300 caracteres alcanzado';
    return '';
  }

  getErrorPuertoServidorMail() {
    return this.formGroup.get('PuertoServidorMail').hasError('required') ? 'El campo es obligatorio' :'';
  }

  getErrorUsuario() {
    var v = this.formGroup.get('Usuario');
    if (v.hasError('maxlength'))
      return 'Límite de 255 caracteres alcanzado';
    return '';
  }

  getErrorClave() {
    var v = this.formGroup.get('Clave');
    if (v.hasError('maxlength'))
      return 'Límite de 255 caracteres alcanzado';
    return '';
  }

  getErrorClaveCertificadoExterno() {
    var v = this.formGroup.get('ClaveCertificadoExterno');
    if (v.hasError('maxlength'))
      return 'Límite de 255 caracteres alcanzado';
    return '';
  }

  getErrorProveedorEnvio(){
    return this.formGroup.get("ProveedorEnvio").hasError('required') ? 'El campo es obligatorio' : '';
  }

  onGuardar() {

    if (!this.formGroup.valid) {
      const formGroupcontrols = this.formGroup.controls;
      Object.keys(formGroupcontrols).forEach(controlName => formGroupcontrols[controlName].markAsTouched());
      this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
      return false;
    }

    if (!this.checkIfGoogleWorkSpace()) {
      this.item.CertificadoExternoUrl = null;
      this.item.ClaveCertificadoExterno = null;
    }

    var r = new SaveMailConfiguracionDto(null, null, null, null, null, null, null, null, null, null, null, null, true);

    var displayName = this.formGroup.get("MailSalienteDisplayName").value;
    if (displayName == null || displayName.length == 0)
      this.formGroup.get("MailSalienteDisplayName").setValue(this.formGroup.get("MailSaliente").value);

    r = 
    {
      ...this.formGroup.value,
      ...
      {
        Id: (this.item?.Id ?? 0),
        CertificadoExternoUrl: (this.item?.CertificadoExternoUrl ?? this.certificadoExternoFileName),
        HabilitarSsl: this.item.HabilitarSsl,
        Clave: this.formGroup.value?.Clave ?? "",
        PuertoServidorMail: this.formGroup.value?.PuertoServidorMail ?? 0,
        Activo: this.item.Activo,
      }
    };

    this.isSaving = true;
    this.mailConfiguracionService.save(r).subscribe(data => {
      this.isSaving = false;
      if (data != null && data.Success && data.Result != null && data.Result.Id > 0) {
        this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
        this.onCancel();
      }
      else
        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
    },
    (err: HttpErrorResponse) => {
      this.isSaving = false;
      this.sharedFunctions.handleError(err, true);
    })
  }

  onEmailTest() {

    if (this.isProcessing()) return false;

    if (!this.formGroup.valid) {
      this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
      return false;
    }

    const dialogRef = this.dialog.open(DialogTextoLibreComponent, {
      width: '500px',
      data: new DialogTextoLibreRequest(
        'Envio de Mail de Prueba',
        'Ingrese el mail al cual se enviará el mail de prueba',
        'Aceptar',
        'Cancelar',
        300
      )
    });

    dialogRef.afterClosed().subscribe((result: DialogTextoLibreResponse) => {
      if (result != null && !result.Cancelled) {

        var mailConfigDto = new SaveMailConfiguracionDto(null, null, null, null, null, null, null, null, null, null, null, null, true);

        var displayName = this.formGroup.get("MailSalienteDisplayName").value;
        if (displayName == null || displayName.length == 0)
          this.formGroup.get("MailSalienteDisplayName").setValue(this.formGroup.get("MailSaliente").value);

        mailConfigDto = 
        {
          ...this.formGroup.value,
          ...
          {
            Id: (this.item?.Id ?? 0),
            CertificadoExternoUrl: (this.item?.CertificadoExternoUrl ?? this.certificadoExternoFileName),
            HabilitarSsl: this.item.HabilitarSsl,
            Clave: this.formGroup.value?.Clave ?? "",
            PuertoServidorMail: this.formGroup.value?.PuertoServidorMail ?? 0,
            Activo: this.item.Activo,
          }
        };

        var request = new SendTestEmailDto(mailConfigDto, result.Result);

        this.isSendingTestMail = true;
        this.mailConfiguracionService.sendTestEmail(request).subscribe(data => {
          this.isSendingTestMail = false;
          if (data != null && data.Success) {
            this.sharedFunctions.showMessage("Se envió correctamente un mail de prueba a la casilla " + result.Result + 
              ". Si la prueba fue exitosa, no olvides de guardar los cambios en la configuración", "Operación Exitosa!");
          }
          else
            this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
        },
        (err: HttpErrorResponse) => {
          this.isSendingTestMail = false;
          this.sharedFunctions.handleError(err, true);
        })
      }
    });
  }

  onCancel() {
    this.location.back();
  }

  checkIfGoogleWorkSpace(){
    return this.formGroup.get("ProveedorEnvio").value != this.googleWorkSpaceId;
  }

  onNewFileAttach(event: any) {
    if (event?.target?.files != null && event.target.files.length > 0) {
      var fileList = event.target.files;

      for (var i=0; i<fileList.length; i++) {
        if (this.item?.CertificadoExternoUrl?.includes(fileList[i]?.name)) {
          setTimeout(() => {
            this.sharedFunctions.showMessage("Ya existe un archivo con ese nombre. Por favor modifique el nombre del archivo o elimine el ya adjuntado", "Error");
          }, 100);
          return false;
        }
      }

      if (fileList != null && fileList.length > 0) {
        this.isFileAttaching = true;
        this.mailConfiguracionService.uploadAttachTemp(fileList).subscribe(data => {
          this.isFileAttaching = false;
          if (data != null && data.Success && data.Result != null && data.Result != null) {
            this.item.CertificadoExternoUrl = data.Result.CertificadoExternoUrl;
            this.certificadoExternoFileName = data.Result.NombreCertificado;
          }
          else
            this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error intentando subir los archivos", "Error");
        },
        (err: HttpErrorResponse) => {
          this.isFileAttaching = false;
          this.sharedFunctions.handleError(err, true);
        })
      }
    }
  }
  
  getFileNameAndExtension(){
    if (this.item?.Id > 0 && this.item?.CertificadoExternoUrl){
      this.certificadoExternoFileName = this.item?.CertificadoExternoUrl.split("/");
      if (this.certificadoExternoFileName != null && this.certificadoExternoFileName.length > 0)
        this.certificadoExternoFileName = this.certificadoExternoFileName[this.certificadoExternoFileName.length - 1]
    }
  }

  deleteCertificado() {
    if (this.item != null)
      this.item.CertificadoExternoUrl = null;
    this.certificadoExternoFileName = null;
  }

  onDelete() {
    if (!this.canDelete) 
        return false;
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: new DialogConfirmRequest(null, "¿Está seguro que desea eliminar la configuracion de mail?", null, null)
    });
    dialogRef.afterClosed().subscribe((result: DialogConfirmResponse) => {
        if (result != null && result.Accepted) {
            this.isDeleting = true;
            this.mailConfiguracionService.delete(this.item.Id).subscribe(data => {
                this.isDeleting = false;
                if (data != null && data.Success && data.Result) {
                    this.sharedFunctions.showMessage("Se eliminaron correctamente los datos!", "Operación Exitosa!");
                    this.onCancel();
                }
                else
                    this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
            },
            (err: HttpErrorResponse) => {
                this.isDeleting = false;
                this.sharedFunctions.handleError(err, true);
            })
        }
    });
  }
}
