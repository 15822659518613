import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { CrudService } from "./crud.service";
import { GenericResponse, Origen, SearchOrigenRequest, SearchOrigenResponse, SaveOrigenDto } from "../model/index";
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})
export class OrigenService extends CrudService<Origen, number, SaveOrigenDto, SearchOrigenRequest> {

  constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
    super(_http,'origen', sharedFunctions);

  }

  getAnonymous(encryptedId: string): Observable<any> {
    return this._http.get<any>(this._serviceUrl + '/GetList/'+encryptedId, this.httpOptions);
  }
}