import { NgModule } from '@angular/core';
import { AuthComponent } from './auth.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthRoutingModule, routedComponents } from './auth-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule } from '@angular/material/slider';
import { DefaultModule } from '../layouts/default/default.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { AutoCompleteModule, ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { LoginComponent } from './login.component';
import { FaqPublicViewComponent } from './faqPublicView.component';
import { CalculadoraPublicComponent } from './calculadoraPublic.component';
import { MatToolbarModule } from '@angular/material/toolbar';

const components = [
    AuthComponent,
  ];
  
  @NgModule({
    imports: [
      AuthRoutingModule,
      HttpClientModule,
      FormsModule,
      CommonModule,
      MatCardModule,
      MatSelectModule,
      MatToolbarModule,
      MatButtonModule,
      MatSliderModule,
      DefaultModule,
      MatCheckboxModule,
      MatGridListModule,
      MatInputModule,
      MatIconModule,
      MatFormFieldModule,
      MatExpansionModule,
      ReactiveFormsModule,
      ComboBoxModule,
      AutoCompleteModule,
      MatAutocompleteModule,
      //BrowserAnimationsModule,
    ],
    declarations: [
      ...routedComponents,
    ],
    exports: [
      LoginComponent,
      FaqPublicViewComponent,
      CalculadoraPublicComponent
    ],
  })
export class AuthModule { }