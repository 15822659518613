import { Component, OnInit } from '@angular/core';
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public menu: any[];
  public user: any;

  constructor(
    private sharedFunctions: SharedFunctions
  ) {
  }

  ngOnInit(): void {
    this.menu = this.sharedFunctions.getLoggedUserMenus();
    this.user = this.sharedFunctions.getLoggedUser();
  }

  validateMenu(menuItem: any) {
    return (menuItem != null && menuItem.Visible);
  }

  hasMenuChildren(menuItem: any) {
    if(menuItem != null && menuItem.MenuHijos != null)
    {
      for(var i in menuItem.MenuHijos)
      {
        if(this.validateMenu(menuItem.MenuHijos[i])) {
          return true;
        }
      }
    }
    return false;
  }

  showMenuIcon(item: any) {
    return (item != null && item.Icono != null && item.Icono.toString().length > 0);
  }
}
