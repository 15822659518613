import { Component, OnInit, ViewChild } from '@angular/core';
import { DataManager, WebApiAdaptor, Query, ReturnOption  } from '@syncfusion/ej2-data';
import { Column, ExcelExportProperties, GridComponent, PageSettingsModel, SelectionSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClickEventArgs } from '@syncfusion/ej2-buttons';
import { DatePipe } from '@angular/common';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { TenantService } from 'src/app/shared/services/tenant.service';
import { MenuFuncionService } from 'src/app/shared/services/menuFuncion.service';

import { GridPageSettings, MenuFuncionCodes, Tenant, SearchTenantRequest } from 'src/app/shared/model';

@Component({
  selector: 'app-tenantLista',
  templateUrl: './tenantLista.component.html',
})

export class TenantListaComponent implements OnInit {
  @ViewChild('grid')
  public grid: GridComponent;

  public data: DataManager;
  total: number = 0;
  searchForm: FormGroup;
  isValidating: boolean = false;
  isSearching: boolean = false;
  isExporting: boolean = false;

  canAdd: boolean = false;
  canView: boolean = false;
  verSoloActivos: boolean = false;

  public pageSizes: number[];
  public pageSettings: PageSettingsModel;
  public query: Query;

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private sharedFunctions: SharedFunctions,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuFuncionService,
    private service: TenantService) {

  }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuService.checkMenuPadre(MenuFuncionCodes.TENANT).subscribe(data => {
      this.isValidating = false;
      if (data != null && data.Success && data?.Result) {
        this.canAdd = data.Result.some(x => x == MenuFuncionCodes.TENANT_ADD);
        this.canView = data.Result.some(x => x == MenuFuncionCodes.TENANT_LIST);
        this.loadView();
      }
      else {
        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
        this.router.navigate(['/']);
      }
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    this.canView = true;
    this.pageSizes = GridPageSettings.PageSizeList;
    this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[0], pageSizes: this.pageSizes };

    this.buildForm();

    this.setQueryParams();
    this.data = new DataManager({
      url: this.service.getSearchUrl(),
      adaptor: new WebApiAdaptor(),
      headers: [this.service.getAuthorizationHeader()]
    });
  }

  buildForm() {
    this.searchForm = this.formBuilder.group({
      'Nombre': [null],
      'NroDocumento': [null]
    });
  }

  setQueryParams() {
    if (this.query == null)
      this.query = new Query();

    var index = this.query.params.findIndex(obj => obj.key === "Nombre");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.Nombre;
    else
      this.query.addParams("Nombre",this.searchForm.value.Nombre);

    index = this.query.params.findIndex(obj => obj.key === "NroDocumento");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.NroDocumento;
    else
      this.query.addParams("NroDocumento",this.searchForm.value.NroDocumento);

    index = this.query.params.findIndex(obj => obj.key === "VerSoloActivos");
    if (index >= 0)
      this.query.params[index].value = this.verSoloActivos.toString();
    else
      this.query.addParams("VerSoloActivos",this.verSoloActivos.toString());
  }

  onGridActionFailure(event: any): void {
    this.sharedFunctions.showMessage("Ocurrió un error intentando realizar la búsqueda", "Error");
  }

  onSearch() {
    this.setQueryParams();
    this.grid.refresh();
  }

  showImage(item: Tenant) {
    return (item?.LogoUrl?.length > 0);
  }

  add() {
    if (!this.canAdd)
      this.router.navigate(['tenant-edit', 0]);
    else
      this.sharedFunctions.showMessage("No tiene permisos para realizar esta operación", "Error");
  }

  edit(item: Tenant){
    if (!item || item.Id == null || item.Id <= 0) {
      this.sharedFunctions.showMessage("Debe seleccionar un item", "Error");
      return false;
    }
    
    this.router.navigate(['tenant-edit', item.Id]);
  }

  onExport() {
    var search = new SearchTenantRequest(null, null, null, "", null, null, this.verSoloActivos);
    search = Object.assign(search, this.searchForm.value);

    this.isExporting = true;
    this.service.exportSearch(search).subscribe((data: Blob) => {
      this.isExporting = false;
      var fileUrl = URL.createObjectURL(data);

      var anchor = document.createElement("a");
      anchor.download = "Tenants_" + this.datePipe.transform(new Date(),"yyyy-MM-dd") + ".csv";
      anchor.href = fileUrl;
      anchor.click();
    },
    (err: HttpErrorResponse) => {
      this.isExporting = false;
      return this.sharedFunctions.handleError(err);
    })
  }
}
