<div class="calculator-container">
  
  <mat-toolbar class="toolbar">
    <mat-toolbar-row class="toolbar-row">
      <span>PAGÁ TUS ENVÍOS A DÓLAR OFICIAL, ¡SIN EL {{porcentajeDolarOficial}}%! </span>
      <div>
        <button class="btn-mi-cuenta toolbar-item" >
          <a href="https://courier.eboxie.com/client/" target="_blank" >Mi cuenta</a>
        </button>

        <button class="btn-registrarme toolbar-item">
          <a href="https://courier.eboxie.com/jc_registro.php" target="_blank" >Registrarme</a>
        </button>

        <button class="btn-tutoriales toolbar-item">
          <a href="https://eboxie.com/tutoriales/" target="_blank">Tutoriales</a>
        </button>
      </div>      
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="whatsapp-button">
    <a href="https://web.whatsapp.com/send?phone=5491138896989" target="_blank">      
      <i class="fab fa-whatsapp fa-3x"></i>     
    </a>
  </div>

  <!-- <div class="logo-image" *ngIf="tenant?.LogoUrl != null && tenant?.LogoUrl.length > 0">
    <img [src]="tenant?.LogoUrl">
  </div> -->

  <div class="calculator-box-header">
    <h2 class="title">¡Cotiza tu envío!</h2>
  </div>

  <div class="calculator-content">

    <form class="calculator-form" (ngSubmit)="calcularEnvio()" #form="ngForm">

      <!-- Primera fila -->
      <div class="form-row">

        <div class="form-field" [ngClass]="{'invalid-field': !valorImportacionValido}">
          <div class="label-group">
            <label>Valor en USD del producto</label>
            <input matInput type="number" class="calculator-input" [(ngModel)]="valorImportacion"
              name="valorImportacion" required width="85%" autocomplete="off" />
          </div>

          <!-- <a (click)="openAyudaModal(valorImportacionHelpText)">
            <i class="fa fa-info-circle form-help-icon" aria-hidden="true" [title]="valorImportacionHelpText"></i>
          </a> -->
        </div>

        <div class="form-field" [ngClass]="{'invalid-field': !pesoImportacionValido}">
          <div class="label-group">
            <label>Peso en KG del producto</label>
            <input matInput type="number" class="calculator-input" [(ngModel)]="pesoImportacion" name="pesoImportacion"
              required width="85%" autocomplete="off" />
          </div>
          <!-- <a (click)="openAyudaModal(pesoImportacionHelpText)">
            <i class="fa fa-info-circle form-help-icon" aria-hidden="true" [title]="pesoImportacionHelpText"></i>
          </a> -->
        </div>

      </div>

      <!-- Segunda fila -->
      <div class="form-row">

        <div class="form-field" [ngClass]="{'invalid-field': !categoriaProductoValida}">
          <div class="label-group">
            <label class="form-label-1">Seleccione un producto</label>
            <form>
              <input matInput type="text" class="calculator-input" [formControl]="productoSearchControl"
                [matAutocomplete]="producto" autocomplete="off">
              <mat-autocomplete #producto="matAutocomplete" [displayWith]="displayProducto"
                (optionSelected)="onProductoSelect()">
                <mat-option *ngFor="let p of filteredProductos" [value]="p">
                  {{ p.Nombre }}
                </mat-option>
              </mat-autocomplete>
            </form>
          </div>
          <!-- <a (click)="openAyudaModal(productoImportacionHelpText)">
            <i class="fa fa-info-circle form-help-icon" aria-hidden="true" [title]="productoImportacionHelpText"></i>
          </a> -->
        </div>

        <div class="form-field" [ngClass]="{'invalid-field': !origenValido}">
          <div class="label-group">
            <label>Origen</label>
            <mat-select class="calculator-select custom-placeholder" [(ngModel)]="selectedOrigenId" name="origenId"
              required>
              <mat-option *ngFor="let origen of origenes" [value]="origen.Id">
                {{ origen.Nombre }}
              </mat-option>
            </mat-select>
          </div>
          <!-- <a (click)="openAyudaModal(origenImportacionHelpText)">
            <i class="fa fa-info-circle form-help-icon" aria-hidden="true" [title]="origenImportacionHelpText"></i>
          </a> -->
        </div>
      </div>

      <div class="calculator-box-header">
        <h2 class="sub-title"> Selecciona la entrega:</h2>
      </div>

      <div class="form-row" style="margin: 0px 10px;">

        <div class="form-field" style="display: flex; gap: 15px;">

          <div class="radio-group" *ngFor="let destino of destinos">

            <input type="radio" id="{{destino.Id}}" name="destinoId" [(ngModel)]="selectedDestinoId" [value]="destino.Id" class="custom-radio">
            <label for="{{destino.Id}}" class="radio-label">{{ destino.Nombre }}</label>

          </div>
          <!-- <a (click)="openAyudaModal(destinoImportacionHelpText)">
            <i class="fa fa-info-circle form-help-icon" aria-hidden="true" [title]="destinoImportacionHelpText"></i>
          </a> -->

        </div>

      </div>

      <div class="centered-button">
        <button class="btn-calcular" type="submit" style="cursor: pointer;" [disabled]="isCalculandoEnvio">
          {{isCalculandoEnvio ? 'Calculando...' : 'Calculá tu envío'}}
        </button>
      </div>

      <div class="calculator-box-header">
        <h2 class="sub-title">Si tenes un código promocional agrégalo acá</h2>
      </div>

      <div class="form-row" style="flex-direction: column;">
        <div class="form-field">
          <input class="calculator-input" matInput [(ngModel)]="codigoPromocional" name="codigoPromocional"
            placeholder="Código" autocomplete="off" />
          <!-- <a (click)="openAyudaModal(codigoPromocionalHelpText)">
            <i class="fa fa-info-circle form-help-icon" aria-hidden="true" [title]="codigoPromocionalHelpText"></i>
          </a> -->
        </div>
        <div class="calculator-box-header">
          <h2 class="sub-title" style="font-size: 20px; font-weight: normal;">Campo NO obligatorio</h2>
        </div>
      </div>

      <!-- Botón "Calcular envío" -->
      <div class="centered-button">
        <button class="btn-borrar" type="submit" style="cursor: pointer;" *ngIf="resultadoCalculo != null" [disabled]="isAgregandoItem">
          {{isAgregandoItem ? 'Calculando...' :  'Agregar item'}}
        </button>
        <button mat-stroked-button color="primary" class="btn-borrar" type="reset" (click)="nuevoPresupuesto()" [disabled]="isCalculandoEnvio">
          Borrar
        </button>
        <button class="btn-cotizar" type="submit" style="cursor: pointer;" [disabled]="isCalculandoEnvio">
          {{isCalculandoEnvio ? 'Calculando...' : 'Cotizar'}}
        </button>
      </div>

    </form>

    <!-- Sección para mostrar los resultados del cálculo -->
    <div id="gridResultados" class="contenedor-tabla" *ngIf="resultadoCalculo != null">

      <div class="logo-image" *ngIf="tenant?.LogoUrl != null && tenant?.LogoUrl.length > 0">
        <img [src]="tenant?.LogoUrl">
      </div>

      <div class="results">

        <table>
          <thead>
            <tr>
              <th style="width: 54%; text-align: left;">
                Impuestos
              </th>
              <th style="width: 13%;">
                Costo Producto (USD)
              </th>
              <th style="width: 13%;">
                Peso (Kg)
              </th>
              <th style="width:17%;">
                USD
              </th>
              <th style="width:3%;"></th>
            </tr>
          </thead>
          <tbody class="scrollable-body" *ngIf="resultadoCalculo.Productos != null">
            <tr *ngFor="let producto of resultadoCalculo.Productos">
              <td style="width: 51%; text-align: left; text-wrap:wrap;">{{ producto.Concepto }}</td>
              <td style="width: 13%;">{{ producto.CostoProducto | number:'1.2-2' }}</td>
              <td style="width: 13%;">{{ producto.Peso | number:'1.2-2' }}</td>
              <td style="width: 18%; text-align: right; text-wrap: nowrap;">US$ {{ producto.Impuestos | number:'1.2-2' }}</td>
              <td style="width: 3%;">
                <a style="cursor: pointer; font-size: 15px;" *ngIf="!producto.IsBorrandoItem" (click)="onItemDelete(producto)" title="Eliminar item">
                  <i class="fa fa-trash fa-1x"></i>
                </a>
                <i *ngIf="producto.IsBorrandoItem" class="fa fa-spinner fa-spin"></i>
              </td>
            </tr>
          </tbody>
        </table>

      </div>

      <hr>

      <div class="tabla-detalle">

        <div class="tabla-titulo">
          Gastos de envío
        </div>

        <!-- Detalle de gastos de envio -->
        <div class="container-detalle">

          <div class="detalle-titulos">
            <p>Flete</p>
            <p>Handling</p>
            <p>Envio a Domicilio</p>
            <p *ngIf="resultadoCalculo.Almacenaje != null">Almacenaje</p>
          </div>

          <div class="detalle-resultados">
            <p>US$ {{resultadoCalculo.Flete | number:'1.2-2'}}</p>
            <p>US$ {{resultadoCalculo.Handling | number:'1.2-2'}}</p>
            <p>US$ {{resultadoCalculo.Envio | number:'1.2-2'}}</p>
            <p *ngIf="resultadoCalculo.Almacenaje != null">US$ {{resultadoCalculo.Almacenaje | number:'1.2-2'}}</p>
          </div>

        </div>

        <!-- Detalle de servicio de compra -->
        <ng-container *ngIf="resultadoServicio != null && (resultadoServicio.TaxTotal != null || resultadoServicio.GestionCompraTotal != null)">

          <div class="tabla-titulo">
            Compramos por vos
          </div>

          <div class="container-detalle">

            <div class="detalle-titulos">
              <p *ngIf="resultadoServicio.ValorImportacion != null">Valor Producto</p>
              <p *ngIf="resultadoServicio.TaxTotal != null">Taxes</p>
              <p *ngIf="resultadoServicio.GestionCompraTotal != null">Gestión</p>
            </div>

            <div class="detalle-resultados">
              <p *ngIf="resultadoServicio.ValorImportacion != null">US$ {{resultadoServicio.ValorImportacion | number:'1.2-2'}}</p>
              <p *ngIf="resultadoServicio.TaxTotal != null">US$ {{resultadoServicio.TaxTotal | number:'1.2-2'}}</p>
              <p *ngIf="resultadoServicio.GestionCompraTotal != null">
                US$ {{resultadoServicio.GestionCompraTotal | number:'1.2-2'}}
              </p>
            </div>
            
          </div>

        </ng-container>

        <!--Totalizador de costo-->
        <div class="container-detalle-precio" style="border-top: 2px solid #501a8e; border-bottom: none;">

          <!--Totalizador de envio SIN servicio de compra-->
          <ng-container *ngIf="resultadoServicio == null || resultadoServicio.TotalServicio == null">

            <div class="detalle-titulos" style="margin-top: 10px;">
              TOTAL DE TU ENVIO
            </div>

            <div class="detalle-resultados" style="margin-top: 10px;">
              <p>US$ {{resultadoCalculo.TotalImportacion | number:'1.2-2'}}</p>
            </div>

          </ng-container>

          <!--Totalizador de envio CON servicio de compra-->
          <ng-container *ngIf="resultadoServicio != null && resultadoServicio.TotalServicio != null">

            <div class="detalle-titulos" style="margin-top: 10px; width: 65%;">
              TOTAL DE TU ENVIO + PRODUCTOS
            </div>

            <div class="detalle-resultados" style="margin-top: 10px;">
              <p>US$ {{resultadoServicio.TotalFinal | number:'1.2-2'}}</p>
            </div>

          </ng-container>

        </div>

        <!--Totalizador en ARS-->
        <div class="container-aprox-pesos" style="margin-bottom: 50px;">

          <!--Totalizador en ARS SIN servicio de compra-->
          <ng-container *ngIf="resultadoServicio == null || resultadoServicio.TotalServicio == null">

            <div class="container-aprox-pesos-row">

              <div class="detalle-titulos">
                <p>TOTAL APROXIMADO EN PESOS</p>
              </div>

              <div class="detalle-resultados">
                <p>ARS {{resultadoCalculo.TotalImportacionConvertido | number:'1.2-2'}}</p>
              </div>

            </div>

            <div class="container-aprox-pesos-row" style="text-align: end; display: inline;">

              <div class="detalle-resultados-nota" style="width: 100%;">
                <p>
                  El total de tu envío lo abonas a tipo de cambio del dólar oficial
                </p>
              </div>

            </div>

          </ng-container>

          <!--Totalizador en ARS CON servicio de compra-->
          <ng-container *ngIf="resultadoServicio != null && resultadoServicio.TotalServicio != null">

            <div class="container-aprox-pesos-row">

              <div class="detalle-titulos-compra">
                <p>TOTAL APROXIMADO EN PESOS</p>
              </div>

              <div class="detalle-resultados-compra">
                <p>ARS {{resultadoServicio.TotalFinalConvertido | number:'1.2-2'}} (*)</p>
              </div>
              
            </div>

            <div class="container-aprox-pesos-row" style="text-align: end; display: inline;">

              <div class="detalle-resultados-nota" style="width: 100%;">
                <p>
                  (*) Pago anticipado de  ARS <b>{{resultadoServicio.MontoPagoAnticipado | number:'1.2-2'}}</b> pago previo a la entrega final de ARS <b>{{resultadoServicio.MontoPagoRestante | number:'1.2-2'}}</b>
                  <br/>
                  Si queres que comencemos el proceso de compra, contáctanos al WhatsApp
                </p>
              </div>

            </div>

          </ng-container>

        </div>

      </div>

      <!--BOTON PARA CALCULAR EL SERVICIO DE COMPRA-->
      <div class="contenedor-detalle-cobro" *ngIf="resultadoServicio == null || resultadoServicio.TotalServicio == null" style="margin-bottom: 20px;">

        <div class="toolbar contenedor-servicio-compra centered-button" style="margin-top: 15px; background-color: transparent;">

          <p class="servicio-compra-title">¿Queres avanzar?</p>
          <br/>
          <p style="font-size: 22px; margin-top: -50px; margin-bottom: 5px;">Seguí los pasos y...</p>
          <button class="btn-calcular" style="cursor: pointer;">
            <a href="https://eboxie.com/tutoriales/" target="_blank" style="color: #501a8e !important; text-decoration: none;">Comenzá tu compra</a>
          </button>

        </div>
        
        <div class="contenedor-servicio-compra centered-button" style="margin-top: 15px;">

          <p class="servicio-compra-title">¿Compramos por Vos?</p>
          <button class="btn-calcular" (click)="servicioCompra()" style="cursor: pointer;" [disabled]="isCalculandoServicioCompra">
            {{isCalculandoServicioCompra ? 'Calculando...' : 'Hacé click acá'}}
          </button>

        </div>
        

      </div>

    </div>

  </div>

</div>