export class MonedaCotizacion {
  constructor(
    public readonly Id: number,
    public readonly FechaCotizacion: Date,
    public readonly ValorCompraOficial: number,
    public readonly ValorVentaOficial: number,
    public readonly ValorCompraTarjeta: number,
    public readonly ValorVentaTarjeta: number,
    public readonly Activo: boolean
  ) {}
}

export class SearchMonedaCotizacionRequest {
  constructor(
    public readonly PageSize: number | null,
    public readonly PageNumber: number | null,
    public readonly OrderAscending: boolean | null,
    public readonly OrderFieldName: string,
    public readonly FechaCotizacionDesde: Date,
    public readonly FechaCotizacionHasta: Date,
    public readonly VerSoloActivos: boolean | null
  ) {}
}
export class SaveMonedaCotizacionDto {
  constructor(
    public readonly FechaCotizacion: Date,
    public readonly ValorCompraOficial: number,
    public readonly ValorVentaOficial: number,
    public readonly ValorCompraTarjeta: number,
    public readonly ValorVentaTarjeta: number,
    public Activo: boolean
  ) {}
}

export class SearchMonedaCotizacionResponse {
  constructor(
    public readonly count: number,
    public readonly result: MonedaCotizacion[]
  ) {}
}
