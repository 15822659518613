export abstract class LocalStorageKeys {
  static readonly Token: string = "token";
  static readonly Menu: string = "menu";
  static readonly Usuario: string = "usuario";
}

export abstract class SignalRConstants {
  static readonly RetriesArray: [2000, 10000, 30000, 60000];
}

export abstract class GridPageSettings {
  static readonly PageSizeList: number[] = [5, 10, 15];
  static readonly PageCount: number = 10;
}

export abstract class EstadoConstants {
  static readonly ESTADO_USUARIO_PENDIENTE: number = 1;
  static readonly ESTADO_USUARIO_APROBADO: number = 2;
  static readonly ESTADO_USUARIO_RECHAZADO: number = 3;
}

export abstract class MensajeUsuarioOpcionEnvioConstants {
  static readonly ROL: number = 1;
  static readonly USUARIO: number = 2;
  static readonly TODOS: number = 3;
}

export abstract class ProveedorEnvioMailConstants {
  static readonly SMTP: number = 1;
  static readonly MAIL_KIT: number = 2;
  static readonly GOOGLE_WORKSPACE: number = 3;
}

export abstract class MenuFuncionCodes {
  static readonly HOME: string = "HOME";
  static readonly ADMIN: string = "ADMIN";
  static readonly PROC: string = "PROC";
  static readonly SEG: string = "SEG";

  static readonly USER: string = "USER";
  static readonly USER_LIST: string = "USER_LIST";
  static readonly USER_ADD: string = "USER_ADD";
  static readonly USER_EDIT: string = "USER_EDIT";
  static readonly USER_DELETE: string = "USER_DELETE";
  static readonly USER_ACTIONS: string = "USER_ACTIONS";

  static readonly PERSONA: string = "PERSONA";
  static readonly PERSONA_LIST: string = "PERSONA_LIST";
  static readonly PERSONA_ADD: string = "PERSONA_ADD";
  static readonly PERSONA_EDIT: string = "PERSONA_EDIT";
  static readonly PERSONA_DELETE: string = "PERSONA_DELETE";

  static readonly ROL: string = "ROL";
  static readonly ROL_LIST: string = "ROL_LIST";
  static readonly ROL_ADD: string = "ROL_ADD";
  static readonly ROL_EDIT: string = "ROL_EDIT";
  static readonly ROL_DELETE: string = "ROL_DELETE";

  static readonly ESTADO: string = "ESTADO";
  static readonly ESTADO_LIST: string = "ESTADO_LIST";
  static readonly ESTADO_ADD: string = "ESTADO_ADD";
  static readonly ESTADO_EDIT: string = "ESTADO_EDIT";
  static readonly ESTADO_DELETE: string = "ESTADO_DELETE";

  static readonly MENU: string = "MENU";
  static readonly MENU_LIST: string = "MENU_LIST";
  static readonly MENU_ADD: string = "MENU_ADD";
  static readonly MENU_EDIT: string = "MENU_EDIT";
  static readonly MENU_DELETE: string = "MENU_DELETE";

  static readonly PARAM_SISTEMA: string = "PARAM_SISTEMA";
  static readonly PARAM_SISTEMA_LIST: string = "PARAM_SISTEMA_LIST";
  static readonly PARAM_SISTEMA_ADD: string = "PARAM_SISTEMA_ADD";
  static readonly PARAM_SISTEMA_EDIT: string = "PARAM_SISTEMA_EDIT";
  static readonly PARAM_SISTEMA_DELETE: string = "PARAM_SISTEMA_DELETE";

  static readonly MENSAJE_USUARIO: string = "MENSAJE_USUARIO";
  static readonly MENSAJE_USUARIO_LIST: string = "MENSAJE_USUARIO_LIST";
  static readonly MENSAJE_USUARIO_ADD: string = "MENSAJE_USUARIO_ADD";
  static readonly MENSAJE_USUARIO_EDIT: string = "MENSAJE_USUARIO_EDIT";
  static readonly MENSAJE_USUARIO_DELETE: string = "MENSAJE_USUARIO_DELETE";

  static readonly MENSAJE_USUARIO_TIPO: string = "MENSAJE_USUARIO_TIPO";
  static readonly MENSAJE_USUARIO_TIPO_LIST: string = "MENSAJE_USUARIO_TIPO_LIST";
  static readonly MENSAJE_USUARIO_TIPO_ADD: string = "MENSAJE_USUARIO_TIPO_ADD";
  static readonly MENSAJE_USUARIO_TIPO_EDIT: string = "MENSAJE_USUARIO_TIPO_EDIT";
  static readonly MENSAJE_USUARIO_TIPO_DELETE: string = "MENSAJE_USUARIO_TIPO_DELETE";

  static readonly MENSAJE_USUARIO_OPC_ENVIO: string = "MENSAJE_USUARIO_OPC_ENVIO";
  static readonly MENSAJE_USUARIO_OPC_ENVIO_LIST: string = "MENSAJE_USUARIO_OPC_ENVIO_LIST";
  static readonly MENSAJE_USUARIO_OPC_ENVIO_ADD: string = "MENSAJE_USUARIO_OPC_ENVIO_ADD";
  static readonly MENSAJE_USUARIO_OPC_ENVIO_EDIT: string = "MENSAJE_USUARIO_OPC_ENVIO_EDIT";
  static readonly MENSAJE_USUARIO_OPC_ENVIO_DELETE: string = "MENSAJE_USUARIO_OPC_ENVIO_DELETE";

  static readonly MAIL_CONFIGURACION: string = "MAIL_CONFIGURACION";
  static readonly MAIL_CONFIGURACION_LIST: string = "MAIL_CONFIGURACION_LIST";
  static readonly MAIL_CONFIGURACION_ADD: string = "MAIL_CONFIGURACION_ADD";
  static readonly MAIL_CONFIGURACION_EDIT: string = "MAIL_CONFIGURACION_EDIT";
  static readonly MAIL_CONFIGURACION_DELETE: string = "MAIL_CONFIGURACION_DELETE";

  static readonly TENANT: string = "TENANT";
  static readonly TENANT_LIST: string = "TENANT_LIST";
  static readonly TENANT_ADD: string = "TENANT_ADD";
  static readonly TENANT_EDIT: string = "TENANT_EDIT";
  static readonly TENANT_DELETE: string = "TENANT_DELETE";
  static readonly TENANT_ADMIN_EDIT: string = "TENANT_ADMIN_EDIT";

  static readonly ROL_TIPO: string = "ROL_TIPO";
  static readonly ROL_TIPO_LIST: string = "ROL_TIPO_LIST";
  static readonly ROL_TIPO_ADD: string = "ROL_TIPO_ADD";
  static readonly ROL_TIPO_EDIT: string = "ROL_TIPO_EDIT";
  static readonly ROL_TIPO_DELETE: string = "ROL_TIPO_DELETE";

  static readonly FAQ: string = "FAQ";
  static readonly FAQ_LIST: string = "FAQ_LIST";
  static readonly FAQ_ADD: string = "FAQ_ADD";
  static readonly FAQ_EDIT: string = "FAQ_EDIT";
  static readonly FAQ_DELETE: string = "FAQ_DELETE";

  static readonly TIPO_DOCUMENTO: string = "TIPO_DOCUMENTO";
  static readonly TIPO_DOCUMENTO_LIST: string = "TIPO_DOCUMENTO_LIST";
  static readonly TIPO_DOCUMENTO_ADD: string = "TIPO_DOCUMENTO_ADD";
  static readonly TIPO_DOCUMENTO_EDIT: string = "TIPO_DOCUMENTO_EDIT";
  static readonly TIPO_DOCUMENTO_DELETE: string = "TIPO_DOCUMENTO_DELETE";

  static readonly  CODIGO_PROMOCIONAL: string = "CODIGO_PROMOCIONAL";
  static readonly  CODIGO_PROMOCIONAL_LIST: string = "CODIGO_PROMOCIONAL_LIST";
  static readonly  CODIGO_PROMOCIONAL_ADD: string = "CODIGO_PROMOCIONAL_ADD";
  static readonly  CODIGO_PROMOCIONAL_EDIT: string = "CODIGO_PROMOCIONAL_EDIT";
  static readonly  CODIGO_PROMOCIONAL_DELETE: string = "CODIGO_PROMOCIONAL_DELETE";
  
  static readonly  DESTINO_ENVIO: string = "DESTINO_ENVIO";
  static readonly  DESTINO_ENVIO_LIST: string = "DESTINO_ENVIO_LIST";
  static readonly  DESTINO_ENVIO_ADD: string = "DESTINO_ENVIO_ADD";
  static readonly  DESTINO_ENVIO_EDIT: string = "DESTINO_ENVIO_EDIT";
  static readonly  DESTINO_ENVIO_DELETE: string = "DESTINO_ENVIO_DELETE";

  static readonly  ORIGEN: string = "ORIGEN";
  static readonly  ORIGEN_LIST: string = "ORIGEN_LIST";
  static readonly  ORIGEN_ADD: string = "ORIGEN_ADD";
  static readonly  ORIGEN_EDIT: string = "ORIGEN_EDIT";
  static readonly  ORIGEN_DELETE: string = "ORIGEN_DELETE";

  static readonly  MONEDA_COTIZACION: string = "MONEDA_COTIZACION";
  static readonly  MONEDA_COTIZACION_LIST: string = "MONEDA_COTIZACION_LIST";
  static readonly  MONEDA_COTIZACION_ADD: string = "MONEDA_COTIZACION_ADD";
  static readonly  MONEDA_COTIZACION_EDIT: string = "MONEDA_COTIZACION_EDIT";
  static readonly  MONEDA_COTIZACION_DELETE: string = "MONEDA_COTIZACION_DELETE";

  static readonly  PRESUPUESTO: string = "PRESUPUESTO";
  static readonly  PRESUPUESTO_LIST: string = "PRESUPUESTO_LIST";
  static readonly  PRESUPUESTO_ADD: string = "PRESUPUESTO_ADD";
  static readonly  PRESUPUESTO_EDIT: string = "PRESUPUESTO_EDIT";
  static readonly  PRESUPUESTO_DELETE: string = "PRESUPUESTO_DELETE";

  static readonly  PRODUCTO: string = "PRODUCTO";
  static readonly  PRODUCTO_LIST: string = "PRODUCTO_LIST";
  static readonly  PRODUCTO_ADD: string = "PRODUCTO_ADD";
  static readonly  PRODUCTO_EDIT: string = "PRODUCTO_EDIT";
  static readonly  PRODUCTO_DELETE: string = "PRODUCTO_DELETE";
}