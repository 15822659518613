import { Component, OnInit, ViewChild } from '@angular/core';
import { DataManager, WebApiAdaptor, Query} from '@syncfusion/ej2-data';
import { Column, ExcelExportProperties, GridComponent, PageSettingsModel, SelectionSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClickEventArgs } from '@syncfusion/ej2-buttons';
import { CommonModule, DatePipe } from '@angular/common';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { ProductoService } from 'src/app/shared/services';
import { MenuFuncionService } from 'src/app/shared/services';

import { GridPageSettings, MenuFuncionCodes, Producto, SearchProductoRequest } from 'src/app/shared/model';

@Component({
  selector: 'app-productoLista',
  templateUrl: './productoLista.component.html',
  styles: [
  ]
})

export class ProductoListaComponent implements OnInit {
  @ViewChild('grid')
  public grid: GridComponent;

  public data: DataManager;
  total: number = 0;
  searchForm: FormGroup;

  isValidating: boolean = false;
  isSearching: boolean = false;
  isExporting: boolean = false;

  canAdd: boolean = false;
  canView: boolean = false;
  verSoloActivos: boolean = false;
  
  public query: Query;

  public selectionOptions: SelectionSettingsModel;
  public pageSizes: number[];
  public toolbarOptions: ToolbarItems[];
  public pageSettings: PageSettingsModel;

  constructor(
    private formBuilder: FormBuilder,
    private sharedFunctions: SharedFunctions,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuFuncionService,
    private service: ProductoService,
    private datePipe: DatePipe) {

  }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuService.checkMenuPadre(MenuFuncionCodes.PRODUCTO).subscribe(data => {
      this.isValidating = false;
      if (data != null && data.Success && data?.Result) {
        this.canAdd = data.Result.some(x => x == MenuFuncionCodes.PRODUCTO_ADD);
        this.canView = data.Result.some(x => x == MenuFuncionCodes.PRODUCTO_LIST);
        if (this.canView) {
          this.loadView();
        }
        else {
          this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
          this.router.navigate(['/']);
        }
      }
      else {
        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
        this.router.navigate(['/']);
      }
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    this.buildForm();

    this.pageSizes = GridPageSettings.PageSizeList;
    this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[1], pageSizes: this.pageSizes };
    this.toolbarOptions = null; //['ExcelExport'];

    this.setQueryParams();
    this.data = new DataManager({
      url: this.service.getSearchUrl(),
      adaptor: new WebApiAdaptor(),
      headers: [this.service.getAuthorizationHeader()]
    });
  }

  buildForm() {
    this.searchForm = this.formBuilder.group({
      'Codigo': [null],
      'Nombre': [null],
      'CodigoArancelario': [null],
    });
  }

  onGridActionFailure(event: any): void {
    this.sharedFunctions.showMessage("Ocurrió un error intentando realizar la búsqueda", "Error");
  }

  onSearch() {
    this.setQueryParams();
    this.grid.refresh();
  }

  setQueryParams() {
    if (this.query == null)
      this.query = new Query();

    var index = this.query.params.findIndex(obj => obj.key === "Codigo");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.Codigo;
    else
      this.query.addParams("Codigo",this.searchForm.value.Codigo);
    
    index = this.query.params.findIndex(obj => obj.key === "Nombre");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.Nombre;
    else
      this.query.addParams("Nombre",this.searchForm.value.Nombre);

    index = this.query.params.findIndex(obj => obj.key === "CodigoArancelario");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.CodigoArancelario;
    else
      this.query.addParams("CodigoArancelario",this.searchForm.value.CodigoArancelario);

    index = this.query.params.findIndex(obj => obj.key === "VerSoloActivos");
    if (index >= 0)
      this.query.params[index].value = this.verSoloActivos.toString();
    else
      this.query.addParams("VerSoloActivos",this.verSoloActivos.toString());
  }

  add() {
    if (this.canAdd) {
      this.router.navigate(['producto-edit', 0]);
    }
    else {
      this.sharedFunctions.showMessage("No tiene permisos para crear un nuevo item", "Error");
    }
  }

  edit(item: Producto) {
    if (!item || item.Id == null || item.Id <= 0) {
      this.sharedFunctions.showMessage("Debe seleccionar un item", "Error");
      return false;
    }
    
    this.router.navigate(['producto-edit', item.Id]);
  }

  onExport() {
    var search = new SearchProductoRequest(null, null, null, "", null, null, null, this.verSoloActivos);
    search = Object.assign(search, this.searchForm.value);

    this.isExporting = true;
    this.service.exportSearch(search).subscribe((data: Blob) => {
      this.isExporting = false;
      var fileUrl = URL.createObjectURL(data);

      var anchor = document.createElement("a");
      anchor.download = "Productos_" + this.datePipe.transform(new Date(),"yyyy-MM-dd") + ".csv";
      anchor.href = fileUrl;
      anchor.click();
    },
    (err: HttpErrorResponse) => {
      this.isExporting = false;
      this.sharedFunctions.handleError(err, true);
    })
  }
}
